// 導入 element ui alert 方法
import { Message } from "element-ui";
import { isEmpty } from "@/service/anyService";
export default {
    namespaced: true,
    actions: {
        errorCallback({ rootState }, { err, callback }) {
            if (err.response === undefined) {
                return Message({
                    type: "error",
                    message: "系統內部錯誤",
                });
            }
            if (err.response.status === 422) {
                return Message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
            if (err.response.status === 500) {
                return Message({
                    type: "error",
                    message: "系統內部錯誤",
                });
            }
            if (isEmpty(err.response)) {
                return Message({
                    type: "error",
                    message: "系統內部錯誤",
                });
            }
            // if (!err.response.data.hasOwnProperty.call("error")) {
            //     return Message({
            //         type: "error",
            //         message: "系統內部錯誤"
            //     });
            // }
            if (err.response.data.message === "Unauthenticated.") {
                return Message({
                    type: "error",
                    message: "token 失效",
                });
            }
            if (err.response === undefined || err.response.data === undefined) {
                return Message({
                    type: "error",
                    message: "系統內部錯誤",
                });
            }
            // 判斷有無傳入 callback
            if (typeof callback === "function") {
                // 判斷有無錯誤代碼
                if (!isEmpty(err.response.data.error.error)) {
                    return callback();
                }
                // 沒有錯誤代碼時回傳系統錯誤
                return Message({
                    type: "error",
                    message: "系統內部錯誤",
                });
            }
            // 判斷有無錯誤代碼
            if (isEmpty(err.response.data.error)) {
                return Message({
                    type: "error",
                    message: "系統內部錯誤",
                });
            }
            console.log(err.response.data, "err.response.data");
            if (!isEmpty(err.response.data.error.error)) {
                if (
                    rootState.i18nStore.errorMessages[
                        err.response.data.error.error
                    ] === undefined
                ) {
                    return Message({
                        type: "error",
                        message: "尚未建立錯誤語系檔",
                    });
                }
                return Message({
                    type: "error",
                    message:
                        rootState.i18nStore.errorMessages[
                            err.response.data.error.error
                        ].message[rootState.i18nStore.locale],
                });
            }
            console.log(rootState.i18nStore.errorMessages);
            // 沒有錯誤代碼時回傳系統錯誤
            return Message({
                type: "error",
                message: "系統內部錯誤",
            });
        },
    },
};
