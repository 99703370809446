import Vue from "vue";
// cms相關 API
import userApi from "./cms/userApi";
import utilsApi from "./cms/utilsApi";
import firebaseAuthApi from "./cms/firebaseAuthApi";
import providerApi from "./cms/providerApi";
import memberApi from "./cms/memberApi";
import chatApi from "./cms/chatApi";
import accountApi from "./cms/accountApi";
import homeSettingApi from "./cms/homeSettingApi";
import orderApi from "./cms/orderApi";
import orderFlashApi from "./cms/orderFlashApi";
import customerComplaintApi from "./cms/customerComplaintApi";
import permessionApi from "./cms/permessionApi";
import campaignApi from "./cms/campaignApi";
import brokerApi from "./cms/brokerApi";
import tagApi from "./cms/tagApi";
// 經紀人相關 API
import brokerLoginApi from "./broker/loginApi";
import brokerHomeApi from "./broker/homeApi";
// 身份審核列表 API
import authenticationApi from "./cms/authenticationApi";
// dashboard API
import dashboardApi from "./cms/dashboardApi";
// 形象首頁設定 API
import homePageSettingApi from "./cms/homePageSettingApi";
// 小鈴鐺通知相關 API
import notificationApi from "./cms/notificationApi"; // 評論相關 API
import commentApi from "./cms/commentApi";
// 城市推廣人 API
import cityPlanApi from "./cms/cityPlanApi";
// 撥款帳戶圖 API
import passbookApi from "./cms/passbookApi";
// 即刻快閃跑馬燈 API
import rightNowActivityMarquee from "./cms/rightnowActivityMarqueeApi";
// 報表 API
import * as reportApi from "./cms/reportApi";
// 行銷資訊 API
import marketApi from "./cms/marketApi";
Vue.prototype.$api = {
    ...userApi,
    ...utilsApi,
    ...firebaseAuthApi,
    ...providerApi,
    ...chatApi,
    ...accountApi,
    ...homeSettingApi,
    ...memberApi,
    ...orderApi,
    ...orderFlashApi,
    ...customerComplaintApi,
    ...permessionApi,
    ...brokerHomeApi,
    ...brokerLoginApi,
    ...campaignApi,
    ...brokerApi,
    ...tagApi,
    ...authenticationApi,
    ...dashboardApi,
    ...homePageSettingApi,
    ...notificationApi,
    ...commentApi,
    ...cityPlanApi,
    ...passbookApi,
    ...rightNowActivityMarquee,
    ...reportApi,
    ...marketApi,
};
