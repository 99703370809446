const accountRouter = {
    path: "/accounting",
    name: "accounting",
    icon: "fas fa-file-invoice-dollar",
    component: () => import("@/views/layout/childLayout/ChildLayoutCache.vue"),
    meta: {
        name: "accounting",
        text: "會計對帳",
    },
    children: [
        {
            path: "stored_list",
            name: "stored_list",
            component: () => import("@/views/accounting/StoredList.vue"),
            meta: {
                name: "stored_list",
                text: "付款記錄",
                parent: "accounting",
                isMenu: true,
            },
        },
        {
            path: "stored_detail/:paymentID",
            name: "stored_detail",
            component: () => import("@/views/accounting/Id.vue"),
            meta: {
                text: "代付款項記錄・詳情",
                parent: "accounting",
                isMenu: false,
            },
        },
        {
            path: "salary_withdraw_record",
            name: "salary_withdraw_record",
            component: () => import("@/views/accounting/WithdrawRecord.vue"),
            meta: {
                name: "salary_withdraw_record",
                text: "代付款項記錄",
                parent: "accounting",
                isMenu: true,
            },
        },
        {
            path: "salary_withdraw_issuance",
            name: "salary_withdraw_issuance",
            component: () =>
                import("@/views/accounting/WithdrawIssuance/index.vue"),
            meta: {
                name: "salary_withdraw_issuance",
                text: "代付款項核發",
                parent: "accounting",
                isMenu: true,
            },
            children: [
                {
                    path: "submit_application",
                    name: "submit_application",
                    component: () =>
                        import(
                            "@/views/accounting/WithdrawIssuance/SubmitApplication.vue"
                        ),
                    meta: {
                        name: "SubmitApplication",
                        text: "立即提領 提出申請",
                        isMenu: false,
                    },
                },
                {
                    path: "immediately_withdraw",
                    name: "immediately_withdraw",
                    component: () =>
                        import(
                            "@/views/accounting/WithdrawIssuance/ImmediatelyWithdraw.vue"
                        ),
                    meta: {
                        name: "ImmediatelyWithdraw",
                        text: "立即提領 撥款處理",
                        isMenu: false,
                    },
                },
                {
                    path: "salary_withdraw",
                    name: "salary_withdraw",
                    component: () =>
                        import(
                            "@/views/accounting/WithdrawIssuance/SalaryWithdraw.vue"
                        ),
                    meta: {
                        name: "SalaryWithdraw",
                        text: "月結款項 撥款處理",
                        isMenu: false,
                    },
                },
                {
                    path: "error_withdraw",
                    name: "error_withdraw",
                    component: () =>
                        import(
                            "@/views/accounting/WithdrawIssuance/ErrorWithdraw.vue"
                        ),
                    meta: {
                        name: "ErrorWithdraw",
                        text: "異常提領",
                        isMenu: false,
                    },
                },
            ],
        },
        {
            path: "invoice_list",
            name: "invoice_list",
            component: () =>
                import("@/views/accounting/WithdrawIssuance/InvoiceList.vue"),
            meta: {
                name: "invoice_list",
                parent: "accounting",
                text: "發票記錄",
                isMenu: true,
            },
        },
        {
            path: "member_wallets",
            name: "member_wallets",
            component: () => import("@/views/accounting/MemberWallets.vue"),
            meta: {
                name: "member_wallets",
                parent: "accounting",
                text: "會員錢包",
                isMenu: true,
            },
        },
        {
            path: "salary_withdraw_broker",
            name: "salary_withdraw_broker",
            component: () =>
                import("@/views/accounting/WithdrawBroker/index.vue"),
            meta: {
                name: "salary_withdraw_broker",
                parent: "accounting",
                text: "介紹人代付款項",
                isMenu: true,
            },
            children: [
                {
                    path: "broker_submit_application",
                    name: "broker_submit_application",
                    component: () =>
                        import(
                            "@/views/accounting/WithdrawBroker/SubmitApplication.vue"
                        ),
                    meta: {
                        name: "broker_submit_application",
                        parent: "salary_withdraw_broker",
                        text: "代付款項 撥款處理",
                        isMenu: false,
                    },
                },
                {
                    path: "broker_error_withdraw",
                    name: "broker_error_withdraw",
                    component: () =>
                        import(
                            "@/views/accounting/WithdrawBroker/ErrorWithdraw.vue"
                        ),
                    meta: {
                        name: "broker_error_withdraw",
                        parent: "salary_withdraw_broker",
                        text: "撥款異常",
                        isMenu: false,
                    },
                },
                {
                    path: "broker_finsh_record",
                    name: "broker_finsh_record",
                    component: () =>
                        import(
                            "@/views/accounting/WithdrawBroker/FinshRecord.vue"
                        ),
                    meta: {
                        name: "broker_finsh_record",
                        parent: "salary_withdraw_broker",
                        text: "撥款完成紀錄",
                        isMenu: false,
                    },
                },
            ],
        },
        {
            path: "salary_withdraw_broker_detail/:id",
            name: "salary_withdraw_broker_detail",
            component: () =>
                import("@/views/accounting/WithdrawBroker/Detail.vue"),
            meta: {
                name: "salary_withdraw_broker_detail",
                parent: "accounting",
                text: "介紹人代付款項·詳情",
                isMenu: false,
            },
        },

        {
            path: "cash_order/:status",
            name: "cash_order",
            component: () => import("@/views/accounting/CashOrder/index.vue"),
            meta: {
                text: "現金繳回款項對帳",
                parent: "accounting",
                isMenu: true,
                params: { status: 0 },
            },
        },
        {
            path: "cash_order/:status/:id",
            name: "cash_order_id",
            component: () => import("@/views/accounting/CashOrder/Form.vue"),
            meta: {
                text: "現金繳回款項對帳・款項詳情",
                parent: "user_list",
                isMenu: false,
            },
        },
        {
            path: "salary_withdraw_salesman/:status",
            name: "salary_withdraw_salesman",
            component: () =>
                import("@/views/accounting/WithdrawSalesman/index.vue"),
            meta: {
                name: "salary_withdraw_salesman",
                parent: "accounting",
                text: "城市推廣人代付款項",
                isMenu: true,
                params: { status: 0 },
            },
        },
        {
            path: "salary_withdraw_salesman_detail/:id",
            name: "salary_withdraw_salesman_detail",
            component: () =>
                import("@/views/accounting/WithdrawSalesman/Detail.vue"),
            meta: {
                name: "salary_withdraw_salesman_detail",
                parent: "accounting",
                text: "城市推廣人代付款項·詳情",
                isMenu: false,
            },
        },
    ],
};

export { accountRouter };
