import { Message } from "element-ui";
import { GetClientUiSwitchSettingApi } from "../../api/cms/utilsApi";

export default {
    namespaced: true,
    state: {
        // 判斷是否固定螢幕
        needFixed: false,
        // ui 顯示開關設定值
        uiSwitchSettingDatas: {},
    },
    mutations: {
        // 設定是否需要固定螢幕
        setFixed(state, val) {
            state.needFixed = val;
        },
        // 設定 ui 顯示開關
        setUiSwitchSettingDatas(state, val) {
            state.uiSwitchSettingDatas = val;
        },
    },
    actions: {
        /**
         * 取得前台顯示設定開關
         * @param {*} param0
         */
        async getClientUiSwitchSetting({ commit }) {
            try {
                const { data } = await GetClientUiSwitchSettingApi();
                commit("setUiSwitchSettingDatas", data.data);
            } catch (err) {
                console.log(err);
                Message({
                    type: "error",
                    message: "取得畫面顯示設定失敗",
                });
            }
        },
    },
};
