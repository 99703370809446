export default {
    namespaced: true,
    state: {
        // dashboard 資料
        dashboardData: [],
    },
    getters: {},
    mutations: {
        // 設定 dashboard 資料
        setDashboard(state, val) {
            state.dashboardData = val;
        },
    },
    actions: {},
};
