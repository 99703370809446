import axios from "@/config/httpConfig";

// nestjs api 網址
const apiConfigByNestjs = process.env.VUE_APP_NESTJS_APICONFIG;

// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;

/**
 * 發送系統通知
 * @param { type Object(物件) } form 通知資料
 * @example {
 * title: 測試標題 { type String (字串)} 通知標題
 * message: 測試內容 { type String (字串)} 通知內容
 * type: system { type String (字串)} 通知類別
 * }
 * @returns
 */
export const SendSystemNotifyApi = (form) => {
    return axios.post(
        `${apiConfigByNestjs}/notification/send-system-notify`,
        form
    );
};

/**
 * 新增系統通知傳送圖片
 * @param { type Object(物件) } data
 * @example {
 *  @param { type File(檔案) } image 圖片檔案
 * }
 */
export const AddNotifyAvatarApi = (data) => {
    return axios.post(`${apiConfig}/backyard/notifications/images`, data);
};

/**
 * 取得新增通知頭像列表
 */
export const GetNotifyAvatarListApi = () => {
    return axios.get(`${apiConfig}/backyard/notifications/images`);
};

export default {
    SendSystemNotifyApi,
    AddNotifyAvatarApi,
    GetNotifyAvatarListApi,
};
