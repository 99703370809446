import axios from "@/config/httpConfig";

// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;
// nestjs api 網址
const apiConfigByNestjs = process.env.VUE_APP_NESTJS_APICONFIG;

/**
 * 取的服務商訂單撥款帳戶圖
 * @param { type String or Number (字串或數字) } userId
 * @returns
 */
export const GetProviderOrderPassbook = async (userId) => {
    return axios.get(`api/backyard/users/${userId}/passbook`);
};

/**
 * 取的城市聯盟撥款帳戶圖
 * @param { type String or Number (字串或數字) } userId
 * @returns
 */
export const GetSalesmanPassbook = async (userId) => {
    return axios.get(`api/backyard/promoters/${userId}/passbook`);
};

export default {
    GetProviderOrderPassbook,
    GetSalesmanPassbook,
};
