import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;
// nestjs api 網址
const apiConfigByNestjs = process.env.VUE_APP_NESTJS_APICONFIG;

/**
 * 判斷 email 是否重複
 * @param { type String(字串) } email 信箱
 */
export const CheckEmailUniqueApi = (email) => {
    return axios.get(`${apiConfig}/auth/verify-email`, { params: { email } });
};

// 取得分類列表
export const GetCategoriesListApi = () => {
    return axios.get(`${apiConfig}/categories`);
};

/**
 * 取得要收到行銷資訊的使用者 email
 * @param { type Object(物件)} params 過濾參數
 * @returns
 */
export const GetMarketingNotificationUserEmailApi = (params) => {
    return axios.get(`${apiConfig}/backyard/useremails`, { params });
};

/**
 * 刪除簡訊驗證碼 api
 * @param { type String(字串) } phone 手機號碼
 * @example {
 *  phone = "886955831666"
 * }
 */
export const DeleteAuthCodeApi = (phone) => {
    return axios.delete(`${apiConfig}/backyard/auth-requests/${phone}`);
};
/**
 * 簡訊驗證碼查詢 By phone
 */
export const GetAuthCodesApi = (phone) => {
    return axios.get(`${apiConfig}/backyard/auth-codes/${phone}`);
};

/**
 * 服務商可參與活動
 */
export const GetCanJoinActivitiesApi = () => {
    return axios.get(`${apiConfigByNestjs}/users/activities`);
};

/**
 * 輸入搜尋關鍵字 自動顯示相關對應地點 api
 * @param { type String(字串) } location 地點
 */
export const AutocompleteLocationApi = (location) => {
    return axios.post(`${apiConfigByNestjs}/google-map/autocomplete`, {
        value: location,
    });
};

/**
 * 取得前台設定顯示開關
 */
export const GetClientUiSwitchSettingApi = () => {
    return axios.get(`${apiConfigByNestjs}/client-setting/ui-switch`);
};

/**
 * 上傳圖片
 * @param { type File(圖片格式) } data
 * @example {
    @param wdhimage  "[image:jpeg,png,jpg,gif,svg | max:10240]" // 電腦版首頁即刻快閃開單背景圖
    @param wmhimage "[image:jpeg,png,jpg,gif,svg | max:10240]" // 手機版首頁即刻快閃開單背景圖
    @param wdimage "[image:jpeg,png,jpg,gif,svg | max:10240]" // 即刻快閃開單頁上方背景圖
    @param ahimage "[image:jpeg,png,jpg,gif,svg | max:10240]" // app 首頁即刻快閃開單背景圖
    @param adimage "[image:jpeg,png,jpg,gif,svg | max:10240]" // app 即刻快閃表單頁圖
 * }
 */
export const UploadClientImgApi = (data) => {
    return axios.post(`${apiConfig}/backyard/expo/background/images`, data);
};
/**
 * 上傳圖片
 * @param { type File(圖片格式) } data
 * @example {
  "background": {
        @param { type String(字串) } wdhimageurl // 電腦版首頁即刻快閃開單背景圖
        @param { type String(字串) } wmhimageurl  // 手機版首頁即刻快閃開單背景圖
        @param { type String(字串) } wdimageurl  // 即刻快閃開單頁上方背景圖
        @param { type String(字串) } ahimageurl // app 首頁即刻快閃開單背景圖
        @param { type String(字串) } adimageurl  // app 即刻快閃表單頁圖
    }
 * }
 */
export const GetClientImgApi = () => {
    return axios.get(`${apiConfig}/backyard/expo/background/images`);
};

/**
 * 取得捐贈碼
 */
export const GetLoveCodesApi = () => {
    return axios.get(`${apiConfig}/dictionaries/love-codes`);
};

/**
 * 設定前台預設值設定檔
 * @param { type Object(物件) } datas
 * @example {
 *   configurations: [
 *     {
 *       @param { type String(字串) } name 標題名稱
 *       @param { type String(字串) } value 設定值
 *       @param { type String(字串) } key 前台對應欄位 key
 *     }
 *   ]
 * }
 */
export const SetClientConfigurationsApi = (datas) => {
    return axios.post(`${apiConfig}/backyard/configurations`, datas);
};
/**
 * 取得前台預設值設定檔
 * @param { type Object(物件) } datas
 * @example {
 *   configurations: [
 *     {
 *       @param { type String(字串) } name 標題名稱
 *       @param { type String(字串) } value 設定值
 *       @param { type String(字串) } key 前台對應欄位 key
 *     }
 *   ]
 * }
 */
export const GetClientConfigurationsApi = () => {
    return axios.get(`${apiConfig}/dictionaries/configurations`);
};

export default {
    CheckEmailUniqueApi,
    GetCategoriesListApi,
    GetMarketingNotificationUserEmailApi,
    DeleteAuthCodeApi,
    GetAuthCodesApi,
    GetCanJoinActivitiesApi,
    AutocompleteLocationApi,
    UploadClientImgApi,
    GetClientImgApi,
    GetLoveCodesApi,
    SetClientConfigurationsApi,
    GetClientConfigurationsApi,
};
