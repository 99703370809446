<template>
    <Dialog :showDialog="param.show" :customClass="'position'">
        <div class="pop-width-m md:p-10 px-5 py-3 bg-white rounded">
            <div class="content">
                <div class="title">{{ param.title }}</div>
                <div class="des" v-html="param.message"></div>
            </div>
            <div class="flex justify-center mt-3 border-gray-100 pt-2">
                <button class="black-btn-outline md:text-sm mr-2 text-xs w-[73px] h-[30px]" @click="cancel">取消</button>
                <button v-if="!param.disabled" class="orange-btn-800 md:text-sm text-xs w-[73px] h-[30px]" @click="confirm">確認</button>
            </div>
        </div>
    </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog.vue";

export default {
    name: "PopupConfirm",
    components: {
        Dialog,
    },
    props: {
        param: {
            type: Object,
            default() {
                return {
                    show: false,
                    title: "",
                    message: "",
                };
            },
        },
    },

    methods: {
        cancel() {
            const param = this.param;
            param.show = false;
            param.cancel && param.cancel();
        },

        confirm() {
            const param = this.param;
            param.show = false;
            param.confirm && param.confirm();
        },
    },
};
</script>

<style lang="scss">
.pop-width-m {
    max-width: 325px;
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .title {
            font-size: 16px;
        }
        .des {
            margin-top: 10px;
            font-size: 12px;
            color: #707070;
        }
    }
}
@media (min-width: 768px) {
    .position {
        top: calc(25% + 85px);
        left: calc(50% - 125px);
    }
}
</style>
