import axios from "axios";
import { Message } from "element-ui";
import { getStorage, removeStorage } from "../service/localStorage";
/**
 * refreshBrokerToken: 重取 broker api token (經紀公司身份)
 * refreshApiToken: 重取 cms api token
 * refreshFirebaseApiToken: 重取 firebase token
 */
import {
    refreshBrokerToken,
    refreshApiToken,
} from "@/service/refreshTokenService";
import store from "../store";
import router from "../router";
const timeout = 300000;
const instance = axios.create({
    timeout,
    baseURL: ``,
    headers: {},
});

// const systemType = process.env.VUE_APP_SYSTEM;

// axios請求之前的動作
instance.interceptors.request.use(
    (config) => {
        config.headers["Accept-Language"] = getStorage("lang");
        if (getStorage("token") !== null) {
            config.headers.Authorization = getStorage("token");
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
// axios請求之後的動作
instance.interceptors.response.use(
    async (response) => {
        // 判斷有token 且 回應api網址非 refresh token 跟 firebase token 網址時才執行
        if (
            getStorage("token") !== null &&
            response.request.responseURL !==
                `${process.env.VUE_APP_APICONFIG}/backyard/refresh` &&
            response.request.responseURL !==
                `${process.env.VUE_APP_APICONFIG}/backyard/chat/tokens`
        ) {
            // // 判斷是否為cms後台
            // if (process.env.VUE_APP_SYSTEM === "cms") {
            //     // 刷新 cms api token
            //     await refreshApiToken();
            //     // 刷新 firebase token
            //     await refreshFirebaseApiToken(
            //         process.env.VUE_APP_SERVICE_CHAT_ID
            //     );
            // }
            // // 判斷是否為經紀公司後台
            // if (process.env.VUE_APP_SYSTEM === "broker") {
            //     // 刷新 broker api token
            //     await refreshBrokerToken();
            // }
        }
        // endLoading();
        return response;
    },
    (error) => {
        if (error && error.message == `timeout of ${timeout}ms exceeded`) {
            Message.error("請求超時");
        }
        // endLoading();
        const { status, data } = error.response;
        if (status == 403) {
            Message.error("權限不足");
        }
        if (status == 401 && data.message === "Unauthenticated.") {
            Message.error("token失效，請重新登入");
            removeStorage("token");
            removeStorage("lang");
            removeStorage("isAuth");
            removeStorage("tokenRefreshTime");
            removeStorage("firebaseTokenRefreshTime");
            store.commit("userStore/setUser", {});
            store.commit("userStore/setIsAuth", false);
            router.push({ name: "login" });
            setTimeout(() => {
                location.reload();
            }, 1000);
        }
        return Promise.reject(error);
    }
);

export default instance;
