const chatControlRouter = {
    path: "/chat_control",
    name: "chat_control",
    icon: "fas fa-comment-alt",
    component: () => import("@/views/layout/childLayout/ChildLayout.vue"),
    meta: {
        name: "chat_control",
        text: "聊天",
    },
    children: [
        // {
        //     path: "/firestore-tools",
        //     name: "firestore-tools",
        //     icon: "fas fa-comment-alt",
        //     component: () => import("@/views/firestore/Tools.vue"),
        //     meta: {
        //         name: "firestore-tools",
        //         parent: "chat_control",
        //         text: "Firestore轉移工具",
        //         icon: "fas fa-comment-alt",
        //         isMenu: true,
        //     },
        // },
        {
            path: "/firestore/cityai",
            name: "firestore-cityai",
            icon: "fas fa-comment-alt",
            component: () => import("@/views/firestore/cityai/index.vue"),
            meta: {
                name: "firestore-cityai",
                parent: "chat_control",
                text: "CityAi智能客服",
                icon: "fas fa-comment-alt",
                isMenu: true,
            },
        },
        {
            path: "/firestore/chats/:tab?/:id?",
            name: "firestore-chats",
            icon: "fas fa-comment-alt",
            component: () => import("@/views/firestore/index.vue"),
            meta: {
                name: "firestore-chats",
                parent: "chat_control",
                text: "聊天室",
                icon: "fas fa-comment-alt",
                isMenu: true,
            },
        },
        {
            path: "/chats-faq-set",
            name: "chats-faq-set",
            icon: "fas fa-comment-alt",
            component: () => import("@/views/chats/faqSet/index.vue"),
            meta: {
                name: "chats-faq-set",
                parent: "chat_control",
                text: "機器人設定",
                icon: "fas fa-comment-alt",
                isMenu: true,
            },
        },
        {
            path: "/firestore/chats-notify",
            name: "firestore-chats-notify",
            icon: "fas fa-comment-alt",
            component: () => import("@/views/firestore/notify/index.vue"),
            meta: {
                name: "firestore-chats-notify",
                parent: "chat_control",
                text: "聊天室推播",
                icon: "fas fa-comment-alt",
                isMenu: true,
            },
        },
        {
            path: "/firestore/chats-tools",
            name: "firestore-chats-tools",
            icon: "fas fa-user-cog",
            component: () => import("@/views/firestore/tools/Settings.vue"),
            meta: {
                name: "firestore-chats-tools",
                parent: "chat_control",
                text: "系統設定值",
                icon: "fas fa-user-cog",
                isMenu: true,
            },
        },
        // {
        //     path: "/chats-doc-fix",
        //     name: "chats-doc-fix",
        //     icon: "fas fa-comment-alt",
        //     component: () => import("@/views/firestore/tools/ServiceChatUserDocFix.vue"),
        //     meta: {
        //         name: "chats-doc-fix",
        //         parent: "chat_control",
        //         text: "客服聊天對象補上",
        //         icon: "fas fa-comment-alt",
        //         isMenu: true,
        //     },
        // },
        // {
    ],
};

export { chatControlRouter };
