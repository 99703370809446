import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;

/**
 * 快閃訂單紀錄
 * @param { type Object(物件) } params 搜尋條件
 * @example {
 *  limit: items per page, default is 30
 *  sort_by: either created_at or started_at
 *  sort_type: asc or desc
 *  name: demand name
 *  user_name: user name
 *  demand_id: demand identity
 *  created_at_start: the min date of created_at
 *  created_at_end: the max date of created_at
 *  started_at_start: the min date of started_at
 *  started_at_end: the max date of started_at
 *  category_id: dating category identity
 *  status: dating status
 * }
 * @returns
 */
const SearchDemandsDatingsApi = (params) => {
    return axios.get(`${apiConfig}/backyard/demands/datings`, { params });
};

/**
 * 取得指定用戶 即刻快閃列表
 * @param { type String(字串) } userID 使用者 id
 * @param { type Object(物件) } params 過濾參數
 * @example {
 *   @param { type Number or String(數字或字串) } page 1 分頁
 *   @param { type Number or String(數字或字串) } limit 10 一頁顯示幾筆
 *   @param { type String(字串) } sort_by started_at 排序 key
 *   @param { type String(字串) enum "desc" | "asc" } order_by desc 倒序或正序
 *   @param { type String(字串) enum "demend_id" | "status" | "started_at_start" | "started_at_end" } other 其他過濾 key
 * }
 */
const getDemandListApi = (userID, params) => {
    return axios.get(`${apiConfig}/backyard/users/${userID}/demands/datings`, {
        params,
    });
};

/**
 * 取得單筆快閃訂單
 * @param { type Object(物件) } id 活動id
 */
const SearchDemandsDatingSingleApi = (id) => {
    return axios.get(`${apiConfig}/backyard/demands/datings/${id}`);
};

/**
 * 更新快閃服務商訂單是否同意預訂
 * @param { type Object(物件) } id 活動id
 */
const UpdateEnrollerDating = (id, params) => {
    return axios.patch(
        `${apiConfig}/backyard/demands/datings/enrollers/${id}`,
        params
    );
};

/**
 * 更新即刻快閃活動紀錄
 * @example {
 *  status: [required | integer | in:-1, -3, -4, 6]
 *  feedback_id:  客訴單編號
 *  note: 變更原因
 * }
 * @returns
 */

const UpdateFlashDating = (id, params) => {
    return axios.patch(`${apiConfig}/backyard/demands/datings/${id}`, params);
};

/**
 * 取得訂單狀態更新LOGS
 * @param { type id } id 訂單編號
 */

const GetFlashOrderLogs = (id) => {
    return axios.get(`${apiConfig}/backyard/demands/datings/${id}/status-logs`);
};

/**
 * 取得即刻快閃訂單服務商查看紀錄
 * @param { type String(字串) } id 即刻快閃訂單 id
 */
const GetFlashOrderProviderViewRecordApi = (id) => {
    return axios.get(`${apiConfig}/backyard/demands/datings/${id}/viewers`);
};

export default {
    SearchDemandsDatingsApi,
    SearchDemandsDatingSingleApi,
    UpdateEnrollerDating,
    UpdateFlashDating,
    GetFlashOrderLogs,
    getDemandListApi,
    GetFlashOrderProviderViewRecordApi,
};
