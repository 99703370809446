const orderManageRouter = {
    path: "/order_manage",
    name: "order_manage",
    icon: "fas fa-calendar ",
    component: () => import("@/views/layout/childLayout/ChildLayoutCache.vue"),
    meta: {
        name: "order_manage",
        text: "訂單管理",
    },
    children: [
        {
            path: "all_order",
            name: "order_manage",
            component: () => import("@/views/orderManage/allList.vue"),
            meta: {
                name: "all_orders",
                text: "預訂單列表",
                parent: "order_manage",
                isMenu: true,
            },
        },
        {
            path: "rightnow_activity_marquee/:type",
            name: "rightnow_activity_marquee",
            component: () =>
                import("@/views/orderManage/rightnowActivityMarquee/index.vue"),
            meta: {
                name: "rightnow_activity_marquee",
                text: "即刻快閃跑馬燈",
                parent: "order_manage",
                isMenu: true,
                params: { type: "real" },
            },
        },
        {
            path: "rightnow_activity_marquee_detail/:type/:id",
            name: "rightnow_activity_marquee_detail",
            component: () =>
                import(
                    "@/views/orderManage/rightnowActivityMarquee/createOrUpdate/index.vue"
                ),
            meta: {
                name: "rightnow_activity_marquee_detail",
                text: "即刻快閃範本單",
                parent: "order_manage",
                isMenu: false,
            },
        },
        {
            path: "rightnow_activity",
            name: "rightnow_activity",
            component: () =>
                import("@/views/orderManage/rightnowActivity/layout.vue"),
            redirect: { name: "rightnow_activity_list" },
            meta: {
                text: "即刻快閃",
                name: "rightnow_activity",
                parent: "order_manage",
                isMenu: true,
            },
            children: [
                {
                    path: "rightnow_activity_list",
                    name: "rightnow_activity_list",
                    component: () =>
                        import(
                            "@/views/orderManage/rightnowActivity/index.vue"
                        ),
                    meta: {
                        text: "即刻快閃列表",
                        name: "rightnow_activity_list",
                        parent: "rightnow_activity",
                        isMenu: true,
                    },
                },
                {
                    path: "rightnow_activity_detail/:id",
                    name: "rightnow_activity_detail",
                    component: () =>
                        import(
                            "@/views/orderManage/rightnowActivity/detail/index.vue"
                        ),
                    meta: {
                        text: "活動紀錄",
                        name: "rightnow_activity_detail",
                        parent: "rightnow_activity",
                        isMenu: false,
                    },
                },
            ],
        },
    ],
};

export { orderManageRouter };
