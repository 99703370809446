import axios from "@/config/httpConfig";
// nestjs api 網址
const apiConfigByNestjs = process.env.VUE_APP_NESTJS_APICONFIG;
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;

/**
 * php api 取得申請成為申請服務商列表
 * @example {
 *  @param { type Array(陣列) } status 審核狀態 enum ([0] = 等待審核,[1] = 審核通過,[-1] = 審核未通過)
 *  @param { type Number(數字) } type 身份驗證類型 enum (2 = 申請成為服務商驗證)
 * }
 */
export const GetApplyToProviderListByPhpApi = (params) => {
    if (params.status) {
        params.status = [params.status];
    }
    return axios.get(`${apiConfig}/backyard/authentications`, {
        params: { ...params, type: 2 },
    });
};

/**
 * 取得準備申請成為服務商列表資料
 * @exmaple {
 *  @param { type Number(數字) } role 服務商身份 enum (3 = 申請上架, 4 = 資料填寫中)
 * }
 */
export const GetUnReadyApplyToProviderListByApi = (params) => {
    return axios.get(`${apiConfig}/backyard/users`, { params });
};

/**
 * 取得申請成為服務商列表
 * @param { type Object(物件) } params 搜尋參數
 * @example {
 *  @param { type Array(陣列) } status 審核狀態 enum ([0] = 等待審核,[1] = 審核通過,[-1] = 審核未通過)
 *  @param { type Number(數字) } type 身份驗證類型 enum (2 = 申請成為服務商驗證)
 * }
 */
export const GetApplyToProviderListApi = (params) => {
    return axios.get(`${apiConfigByNestjs}/authentication/apply-to-providers`, {
        params: { ...params, type: 2 },
    });
};

/**
 * 取得申請服務商驗證資料
 * @param { type Number(數字) } id 申請 id
 */
export const GetApplyToProviderApi = (id) => {
    return axios.get(
        `${apiConfigByNestjs}/authentication/apply-to-provider/${id}`
    );
};

/**
 * php api 取得申請成為服務商資料
 */
export const GetApplyToProviderByPhpApi = (id) => {
    return axios.get(`${apiConfig}/backyard/authentications/${id}`);
};

/**
 * 更新服務商驗證資料
 * @param { type Number(數字) } id 申請 id
 * @param { type Object(物件) } data 申請資料
 */
export const UpdateApplayToProviderApi = ({ id, data }) => {
    return axios.patch(`${apiConfig}/backyard/authentications/${id}`, data);
};

export default {
    GetApplyToProviderListByPhpApi,
    GetApplyToProviderListApi,
    GetUnReadyApplyToProviderListByApi,
    GetApplyToProviderApi,
    GetApplyToProviderByPhpApi,
    UpdateApplayToProviderApi,
};
