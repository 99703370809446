export default {
    namespaced: true,
    // data
    state: {
        // 儲存供給者資料
        providerData: {
            promoter: { user: { thumbnails: { avatar: {} } } },
            promotee: {},
            allSKills: [],
            activities: [],
            skills: [],
            acceptable_activities: {
                activities: [],
            },
            experienced_skills: {
                skills: [],
            },
        },
    },
    // computed
    getters: {},
    // methods
    mutations: {
        // 設定供給者資料
        setProviderData(state, val) {
            state.providerData = val;
        },
        // 設定服務商名稱
        setProviderName(state, val) {
            state.providerName = val;
        },
    },
    // mounted ajax的方法都寫在這
    actions: {},
};
