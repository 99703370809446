<template>
    <div id="app" class="w-full" :class="showDark ? 'dark' : ''">
        <router-view></router-view>
    </div>
</template>

<script>
import { mapState } from "vuex";
/**
 * refreshBrokerToken: 重取 broker api token (經紀公司身份)
 * refreshApiToken: 重取 cms api token
 * refreshFirebaseApiToken: 重取 firebase token
 */
import {
    refreshBrokerToken,
    refreshApiToken,
    refreshFirebaseApiToken,
} from "@/service/refreshTokenService";
import { getStorage, removeStorage, setStorage } from "@/service/localStorage";

export default {
    computed: {
        ...mapState(["showDark"]),
        ...mapState("userStore", ["isAuth"]),
    },
    data() {
        return {
            setIntervalWork: false,
        };
    },
    methods: {
        async refreshTokenWork() {
            let i = 0;
            // 依據env參數不同針對是否為 broker 或
            if (
                process.env.VUE_APP_SYSTEM === "cms" &&
                !this.$isEmpty(getStorage("token"))
            ) {
                setInterval(async () => {
                    i++;
                    console.log("interval cms work =>", i);
                    // 重取 cms token
                    await refreshApiToken();
                    // 重取 firebase token
                    await refreshFirebaseApiToken(
                        process.env.VUE_APP_SERVICE_CHAT_ID
                    );
                    this.setIntervalWork = true;
                }, 1000 * 60 * 10);
            } else if (!this.$isEmpty(getStorage("token"))) {
                setInterval(async () => {
                    i++;
                    console.log("interval broker work =>", i);
                    // 重取 經紀後台 token
                    await refreshBrokerToken();
                    this.setIntervalWork = true;
                }, 1000 * 60 * 10);
            } else {
                console.log("token =>", !this.$isEmpty(getStorage("token")));
            }
        },
    },
    watch: {
        async isAuth(val) {
            if (val && !this.setIntervalWork) {
                // await refreshApiToken();
                // await this.refreshTokenWork();
                console.log("is auth =>", val);
            }
        },
    },
    async mounted() {
        // await this.refreshTokenWork();
    },
};
</script>
