import moment from "moment";
//判斷是否為空值或空物件
export const isEmpty = (value) => {
    return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0) ||
        value.length === 0
    );
};

/**
 * 判斷語系檔 是否存在 不存在則回傳key
 * @param { type Object (物件) 該值為語系檔的值} value
 * @param { type String (字串) 該值為檔案名稱 } langFile
 * @param { type String (字串) 該值為要傳入對應語系檔得key } langKey
 */
export const checkLang = function (value, langFile, langKey) {
    if (value !== null) {
        if (value[langFile] !== null && value[langFile] !== undefined) {
            if (value[langFile][langKey] === undefined) {
                return langKey;
            } else {
                return value[langFile][langKey].name;
            }
        } else {
            return "連語系檔都還沒有";
        }
    }
};

// 貨幣判斷三位數增加逗號
export const formatCurrency = function (num) {
    const result = Math.floor(num * 100) / 100;
    return result
        .toString()
        .replace(
            /^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/,
            function (all, pre, groupOf3Digital) {
                return pre + groupOf3Digital.replace(/\d{3}/g, ",$&");
            }
        );
};

export const formatDateTime = (val) =>
    val ? moment(val).format("YYYY/MM/DD HH:mm:ss") : "-";

/**
 * @param { type = Array  傳入要搜尋條件得陣列值 } val
 * @param { type = Array  傳入要搜尋條件所匹配的key } key
 * @param { type = String 傳入要回傳的物件變數 } objVar
 */
export const checkHaveSelectData = function (val, key, objVar) {
    val.forEach((item, index) => {
        if (item === null || item === "" || item === undefined) {
            delete objVar[key[index]];
        } else {
            objVar[key[index]] = item;
            return objVar;
        }
    });
};

/**
 * 判斷圖片是否為 404 或載入失敗
 * @param { type String(字串) } url 圖片網址
 * @returns
 */
export const imageLoadError = function (url) {
    return new Promise((resolve) => {
        var img = new Image();
        img.onload = function () {
            if (this.complete == true) {
                resolve(true);
                img = null;
            }
        };
        img.onerror = function () {
            resolve(false);
            img = null;
        };
        img.src = url;
    });
};

//產生隨機數
export const getRandom = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * 判斷表單是否有值
 * @param { type Array(陣列) } formKeys 表單key
 * @param { type Object(物件) } form 表單資料
 */
export const checkFormData = (formKeys, form) => {
    let isDisabled = false;
    // 沒有輸入資料的欄位 key
    let notHaveKey = [];
    formKeys.forEach((item) => {
        // 判斷是否有傳送表單key 且 這個值沒有 empty
        if (!form.hasOwnProperty(item) || isEmpty(form[item])) {
            notHaveKey.push(item);
            isDisabled = true;
        }
        // 判斷是否有傳送表單key
        if (form.hasOwnProperty(item)) {
            // 判斷是否有未傳送的指定 key 如果有的話 還是會讓 disabled 狀態無法解除
            isDisabled = notHaveKey.length > 0 ? true : false;
        }
    });
    return isDisabled;
};

// 建立聊天對象客服資料
export const serviceChatData = {
    banana_id: process.env.VUE_APP_SERVICE_CHAT_ID,
    name: "城市小幫手 Rita",
    avatar: "",
    type: "citybanana_service",
};

/**
 * 36進位演算法
 * @param { type Number(數字) } n 數字
 * @param { type Number(數字) } limit 回傳字串限制
 * @returns
 */
export const scale36 = async (n, limit) => {
    function getNums36() {
        const nums36 = [];
        for (let i = 0; i < 36; i++) {
            if (i >= 0 && i <= 9) {
                nums36.push(i);
            } else {
                nums36.push(String.fromCharCode(i + 87));
            }
        }
        return nums36;
    }
    // 單獨的功能函式
    // 16進位制數： 0-9  a-f    36進位制數： 0-9  a-z
    const arr = [];
    const nums36 = getNums36();
    // 36 10
    if (!Number.isInteger(n)) {
        //浮點數判斷，目前不支援小鼠
        console.warn("不支援小數轉換");
        return n;
    }
    let neg = "";
    if (n < 0) {
        //對負數的處理
        neg = "-";
        n = Math.abs(n);
    }
    while (n) {
        const res = n % 36;
        arr.unshift(nums36[res]);
        // 進位
        n = Math.floor(n / 36);
    }
    arr.unshift(neg);
    // 只回傳6位字數就好
    return arr.join("").toString().slice(0, limit);
};

/**
 * 新增 亂數 id
 * @param { type String(字串) } type 亂數 id 開頭字串
 * @param { type Number(數字)} unixtime unixtime 時間
 * @param { type Number(數字) } limit 回傳字串限制
 * 首碼 u 是使用者, 儲值是 s, 預約單是 d 開頭
 */
export async function createHashId(type, unixtime, limit) {
    const r1 = getRandom(0, 9);
    const r2 = getRandom(0, 9);
    const r3 = getRandom(0, 9);
    const key = await scale36(unixtime, limit);
    return `${type}${key}${r1}${r2}${r3}`;
}
