import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;

/**
 * 取得評論列表
 * @param { type String(字串) } userId 使用者 id
 * @param { type Object(物件) } params 搜尋與分頁條件
 * @example https://github.com/citybanana/citybanana-document/blob/main/doc/specifications/cms/reviews.md
 * @returns
 */
export const GetCommentListApi = (userId, params) => {
    return axios.get(`${apiConfig}/backyard/users/${userId}/reviews`, { params });
};
/**
 * 取得單一評論資料
 * @param { type String(字串) } commentId 評論 id
 */
export const GetCommentApi = (commentId) => {
    return axios.get(`${apiConfig}/backyard/reviews/${commentId}`);
};
/**
 * 刪除評論
 * @param { type String(字串) } commentId 評論 id
 */
export const DeleteCommentApi = (commentId) => {
    return axios.delete(`${apiConfig}/backyard/reviews/${commentId}`);
};
/**
 * 刪除評論
 * @param { type String(字串) } commentId 評論 id
 * @param { type Number(數字) } status 評論開關狀態 (0 = 開啟, -1 = 關閉)
 * @example {
 *  commentId: 1
 *  status: -1
 * }
 */
export const UpdateCommentStatusApi = (commentId, status) => {
    return axios.patch(`${apiConfig}/backyard/reviews/${commentId}`, { status });
};

/**
 * 新增評論
 * @param { type Object(物件) } form 表單資料
 * @example {
 *   user_id: 1 { type Number(數字) } 使用者 id,
 *   reviewer_real_name: 1 { type String(字串) } 使用者名稱,
 *   score: 1 { type Number(數字) } 評分,
 *   comment: 1 { type String(字串) } 評論,
 *   reviewed_at: 1 { type Date() } 評論時間,
 *   avatar: 1 { type File(檔案) } 大頭照
 * }
 */
export const AddCommentApi = (form) => {
    return axios.post(`${apiConfig}/backyard/reviews`, form);
};

export default {
    GetCommentListApi,
    GetCommentApi,
    DeleteCommentApi,
    UpdateCommentStatusApi,
    AddCommentApi,
};
