import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;

/**
 * 取得用戶分享體驗
 * @returns 
 * {
    "media": [
        {
            "id": 1,
            "title": "測試標題",
            "description": "測試描述",
            "url": "https://citybanana.com",
            "photos": {
                "logo": {
                    "url": "https://cdn-dev.citybanana.com/media/rjpc5n066.jpg?1665681611",
                    "file": "rjpc5n066.jpg"
                },
                "screenshot": {
                    "url": "https://cdn-dev.citybanana.com/media/rjpc5n969.jpg?1665681611",
                    "file": "rjpc5n969.jpg"
                }
            },
            "sequence": 1,
            "administrator_id": 1
        }
    ]
}
 */
export const GetUserExperiencesListApi = () => {
    return axios.get(`${apiConfig}/backyard/expo/media`);
};

/**
 * 新增使用者體驗
 * @param { type Object(物件) } form 新增資料
 * @example {
 *   title: "xxx標題" {type String(字串) 最多250字 } 標題 (必填)
 *   description: "xxx描述" {type String(字串) 最多250字 } 描述 (非必填)
 *   url: "https://www.google.com" { type String(字串) } 網址 (必填)
 *   sequence: 1 { type Number(數字) } 排序 (非必填)
 *   screenshot: { type File(檔案)  尺寸 466w*194h mm 檔案最大 5mb } 大圖顯示
 *   logo: { type File(檔案)  尺寸 100w*100h mm 檔案最大 5mb } 小圖顯示
 *  }
 */
export const CreateUserExperienceApi = (form) => {
    return axios.post(`${apiConfig}/backyard/expo/media`, form);
};
/**
 * 刪除使用者體驗
 * @param { type String or Number(字串或數字) } id 刪除使用者體驗 id
 */
export const DeleteUserExperienceApi = (id) => {
    return axios.delete(`${apiConfig}/backyard/expo/media/${id}`);
};

export default {
    GetUserExperiencesListApi,
    CreateUserExperienceApi,
    DeleteUserExperienceApi,
};
