export default {
    path: "/tag_control",
    name: "tag_control",
    icon: "fas fa-tags",
    component: () => import("@/views/layout/childLayout/ChildLayout.vue"),
    meta: {
        name: "tag_control",
        text: "標籤控制",
    },
    children: [
        {
            path: "user_tags",
            name: "user_tags",
            component: () => import("@/views/tagControl/userTags/index.vue"),
            meta: {
                text: "標記使用者",
                name: "user_tags",
                parent: "tag_control",
                isMenu: true,
            },
        },
        {
            path: "tag_list",
            name: "tag_list",
            component: () => import("@/views/tagControl/index.vue"),
            meta: {
                text: "標籤列表",
                name: "tag_list",
                parent: "tag_control",
                isMenu: true,
            },
        },
    ],
};
