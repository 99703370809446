export default {
    path: "/report_control",
    name: "report_control",
    icon: "fas fa-chart-bar",
    component: () => import("@/views/layout/childLayout/ChildLayout.vue"),
    meta: {
        name: "tag_control",
        text: "報表管理",
    },
    children: [
        {
            path: "vorder_report",
            name: "vorder_report",
            component: () => import("@/views/reportControl/vorder/index.vue"),
            meta: {
                text: "詢問單統計",
                name: "vorder_rpeort",
                parent: "report_control",
                isMenu: true,
            },
        },
    ],
};
