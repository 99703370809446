import firebase from "firebase/app";
import { db } from "@/plugins/firebase";
// firebase 登入機制
import { firebaseAuth } from "@/plugins/firebase";
import { Message } from "element-ui";
import { isEmpty } from "@/service/anyService";

export default {
    namespaced: true,
    state: {
        // 未讀聊天訊息數量
        unReadMessageCount: 0,
        // 會員未讀訊息數量統計
        unReadMessageCountByMember: 0,
        // 服務商未讀訊息數量統計
        unReadMessageCountByProvider: 0,
        // 聊天對象 id
        currentReceiveUserId: null,
        // 聊天對象資料
        currentUserInfo: {
            userData: {},
        },
    },
    getters: {
        // 即時的聊天訊息未讀數量
        realtimeUnReadMessageCount: (state) => {
            // 判斷未讀訊息數量是否大於 99
            if (state.unReadMessageCount > 99) {
                return "99+";
            }
            return state.unReadMessageCount;
        },
    },
    mutations: {
        // 設定聊天對象 id
        setCurrentReceiveUserId(state, val) {
            state.currentReceiveUserId = val;
        },
        // 設定聊天對象資料
        setCurrentUserInfo(state, val) {
            state.currentUserInfo = val;
        },
        // 設定未讀聊天訊息數量總計
        setUnReadMessageCount(state, val) {
            state.unReadMessageCount = val;
        },
        // 設定會員未讀訊息數量
        setUnReadMessageCountByMember(state, val) {
            state.unReadMessageCountByMember = val;
        },
        // 設定服務商未讀訊息數量
        setUnReadMessageCountByProvider(state, val) {
            state.unReadMessageCountByProvider = val;
        },
    },
    actions: {
        // eslint-disable-next-line no-empty-pattern
        async getFirebaseTokenByBroker({ dispatch }) {
            try {
                const {
                    data: { token },
                } = await this._vm.$api.GetFirebaseCustomBrokerTokenApi();
                await dispatch("loginFirebaseAuth", token);
            } catch (err) {
                console.log(err);
                Message({
                    type: "error",
                    message: "取得firebase broker token 失敗",
                });
            }
        },
        /**
         * 取得 firebase admin token
         * @param { type String(字串) } userId 使用者 banana_id
         */
        async getFirebaseToken({ dispatch }, userId) {
            try {
                const {
                    data: { token },
                } = await this._vm.$api.GetFirebaseCustomTokenApi(userId);
                await dispatch("loginFirebaseAuth", token);
                return token;
            } catch (err) {
                Message({
                    type: "error",
                    message: "取得firebase admin token 失敗",
                });
            }
        },
        /**
         * 登入firebase使用者
         */
        // eslint-disable-next-line no-empty-pattern
        async loginFirebaseAuth({}, token) {
            try {
                // 將登入改為永久時效性 除非執行登出才會失效
                await firebaseAuth().setPersistence(
                    firebase.auth.Auth.Persistence.LOCAL
                );
                try {
                    await firebaseAuth().signInWithCustomToken(token);
                } catch (err) {
                    Message({
                        type: "error",
                        message: "firebase 登入失敗",
                    });
                }
            } catch (error) {
                const { code: errorCode, message: errorMessage } = error;
                return { errorCode, errorMessage };
            }
        },
        /**
         * 登出 firebase 使用者
         */
        async logoutFirebaseAuth() {
            try {
                await firebaseAuth().signOut();
            } catch (err) {
                Message({
                    type: "error",
                    message: "firebase 登出失敗",
                });
            }
        },
        getUnReadMessageCount({ commit }, userId) {
            db()
                .doc(`chat_rooms/${userId}`)
                .onSnapshot(
                    (snapshot) => {
                        // 判斷未取得資料時給予預設值
                        if (!snapshot.exists) {
                            commit("setUnReadMessageCount", 0);
                            return;
                        }
                        // 取得會員未讀訊息總計
                        const unReadMessageCountByMember = isEmpty(
                            snapshot.data().unReadMessageCountByMember
                        )
                            ? 0
                            : snapshot.data().unReadMessageCountByMember;
                        // 取得會員未讀訊息總計
                        const unReadMessageCountByProvider = isEmpty(
                            snapshot.data().unReadMessageCountByProvider
                        )
                            ? 0
                            : snapshot.data().unReadMessageCountByProvider;
                        // 取得全部未讀訊息總計
                        const unReadMessageCount = isEmpty(
                            snapshot.data().unReadMessageCount
                        )
                            ? 0
                            : snapshot.data().unReadMessageCount;
                        // 設定會員未讀訊息數量
                        commit(
                            "setUnReadMessageCountByMember",
                            unReadMessageCountByMember
                        );
                        //  設定服務商未讀訊息數量
                        commit(
                            "setUnReadMessageCountByProvider",
                            unReadMessageCountByProvider
                        );
                        // 設定未讀訊息總計
                        commit("setUnReadMessageCount", unReadMessageCount);
                    },
                    (error) => {
                        console.log("取得未讀訊息數量總計失敗", error);
                    }
                );
        },
    },
};
