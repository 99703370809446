import axios from "@/config/httpConfig";
// nestjs api 網址
const apiConfigByNestjs = process.env.VUE_APP_NESTJS_APICONFIG;

/**
 * 上傳聊天室檔案api
 * @param { type String or Number(字串或數字) } time 字串或數字
 * @example {
 *  0 => 過去七天
    1 => 過去30天
    2 => 當週
    3 => 上週
    4 => 當月
    5 => 上週
    6 => 過去90天
    7 => 當季
    8 => 上季
    9 => 當年
    10 => 去年
 * 
 * }
 */
export const DashboardApi = (time = 0) => {
    return axios.get(`${apiConfigByNestjs}/report/${time}`);
};

export default {
    DashboardApi,
};
