// 權限路由過濾
export function permissionRouter(allRouter = [], permissions = [], isAdmin = false) {
    // 過濾後的 routes
    const permissionRoutes = [];
    // permissions = [] 是最高權限 , CRUD 全開
    if (isAdmin) {
        allRouter.forEach((Rvalue) => {
            // 判斷此路由是否有 新增｜更新｜刪除權限
            Rvalue.meta.params = {
                Create: true,
                Read: true,
                Update: true,
                Delete: true,
                value: Rvalue.meta.params,
            };
            // 如果有子選單且數量大於0
            if (Rvalue.children && Rvalue.children.length > 0) {
                // callback 呼叫自己遞回
                Rvalue.children = permissionRouter(Rvalue.children, [], isAdmin);
            }
            permissionRoutes.push(Rvalue);
        });
    } else {
        // 非最高權限設定 各自CRUD
        allRouter.forEach((Rvalue) => {
            permissions.forEach((Pitem) => {
                const { Create, Read, Update, Delete } = convertToBinary(Pitem.operation);
                // 可讀才加入
                if (!Read) {
                    return;
                }
                if (Pitem.key === Rvalue.name) {
                    // 判斷此路由是否有 新增｜更新｜刪除權限
                    Rvalue.meta.params = {
                        Create,
                        Read,
                        Update,
                        Delete,
                    };
                    // 判斷是否為選單用路由
                    Rvalue.meta.isMenu = Pitem.is_menu !== 0;
                    // 判斷是否為子選單用路由
                    // if (features.length > 0) {
                    //     Rvalue.meta.params["features"] = features;
                    // }
                    // 判斷是否有子項功能
                    if (Pitem.setting.length > 0) {
                        Rvalue.meta.setting = JSON.parse(Pitem.setting);
                    } else {
                        Rvalue.meta.setting = [];
                    }
                    // 判斷是否顯示 頁面中含有額外的 左側選單
                    if (Pitem.parent_features) {
                        Rvalue.meta.params["sideItems"] = JSON.parse(Pitem.parent_features);
                    }
                    // 如果有子選單且數量大於0
                    if (Rvalue.children && Rvalue.children.length > 0) {
                        // callback 呼叫自己遞回
                        Rvalue.children = permissionRouter(Rvalue.children, permissions);
                    }
                    permissionRoutes.push(Rvalue);
                }
            });
        });
    }
    return permissionRoutes;
}
/**
 *
 * @param {Array} routes 用戶過濾後的路由
 *
 * 遞迴為所有有子路由的路由設置第一個children.path為默認路由
 */
export function setDefaultRoute(routes) {
    routes.forEach((value) => {
        // 判斷路由的children大於0
        if (value.children && value.children.length > 0) {
            value.redirect = { name: value.children[0].name };
            // setDefaultRoute(value.children);
        }
    });
}

//  operation 轉換為二進制帶出crud格式
export function convertToBinary(number) {
    let num = number;
    let binary = (num % 2).toString();
    for (; num > 1; ) {
        num = parseInt(num / 2);
        binary = (num % 2) + binary;
    }
    binary = binary.split("");
    if (binary.length < 4) {
        for (let i = binary.length; i < 4; i++) {
            binary.splice(0, 0, "0");
        }
    }
    return {
        Read: binary[3] == 1,
        Create: binary[2] == 1,
        Update: binary[1] == 1,
        Delete: binary[0] == 1,
    };
}
