import axios from "@/config/httpConfig";

// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;
// nestjs api 網址
const apiConfigByNestjs = process.env.VUE_APP_NESTJS_APICONFIG;

/**
 * 取得城市推廣人列表
 * @param {*} params
 * @example {
 *  @key statuses: 1 { type String(字串) } 申請狀態
 *  @key phone: 886955831 { type String(字串) } 電話
 *  @key sort_by: name { type String(字串) } 排序欄位名稱
 *  @key sort_type: desc or asc { type String(字串) } 排序方式（倒序或者正序）
 *  @key created_at_start: 2022-12-22 { type DateString(日期字串) } 開始時間
 *  @key created_at_end: 2022-12-26 { type DateString(日期字串) } 結束時間
 * }
 */
export const GetApplyCityPlanSalesmanListApi = (params) => {
    return axios.get(`${apiConfig}/backyard/promoters`, { params });
};
/**
 * 取得單一城市推廣人
 * @param { type String or Number(字串或數字) } userId (promoters表的 user_id)
 */
export const GetApplyCityPlanSalesmanApi = (userId) => {
    return axios.get(`${apiConfig}/backyard/promoters/${userId}`);
};
/**
 * 審核城市推廣人
 * @param { type String(字串) } userId
 * @param { type Object(物件) } form
 * @example {
 *  @key status: 1 or -1 { type Number(數字) } 申請狀態
 *  @key comment: 審核成功備注 { type String(字串) } 備注 ( status = -1 時 需送此 key )
 * }
 */
export const VerifyCityPlanSalesmanApi = (userId, form) => {
    return axios.post(`${apiConfig}/backyard/promoters/${userId}/review`, form);
};

/**
 * 更新城市推廣人審核狀態
 * @param { type Object(物件) } data
 * @example {
 *  @key userId: { type String(字串) } 使用者 id
 *  @key status: { type Number(數字) }
 * }
 */
export const UpdateCityPlanSalesmanStatusApi = (data) => {
    return axios.patch(`${apiConfig}/backyard/promoters/${data.userId}`, {
        status: data.status,
        invitation_code: data.invitation_code,
    });
};

/**
 * 更新城市推廣人撥款帳戶
 * @param { type String or Number(字串或數字) } id 資料 id
 * @param { type Object(物件) } data
 * @example {
 *  @key swift: 812 { type String(字串) } 分行代碼
 *  @key bankCode: 812 { type String(字串) } 銀行代碼
 *  @key accountId: 92812312322 { type String(字串) } 銀行帳號
 *  @key accountName: 王小明 { type String(字串) } 銀行戶名
 *  @key image: 存摺照片 { type File(檔案) } 存摺照片
 * }
 */
export const UpdateCityPlanSalesmanBankAccountApi = (id, data) => {
    return axios.post(`${apiConfig}/backyard/promoters/${id}/passbook`, data);
};

/**
 * 取得城市推廣人下線列表
 * @param { type String(字串) } userId
 * @param {*} params
 * @example {
 *  @key name: 王小明 { type String(字串) } 下線暱稱
 *  @key phone: 886955831 { type String(字串) } 電話
 *  @key limit: 10 { type Number(數字) } 每頁幾筆資料
 *  @key sort_by: name { type String(字串) } 排序欄位名稱
 *  @key sort_type: desc or asc { type String(字串) } 排序方式（倒序或者正序）
 *  @key created_at_start: 2022-12-22 { type DateString(日期字串) } 開始時間
 *  @key created_at_end: 2022-12-26 { type DateString(日期字串) } 結束時間
 * }
 */
export const GetApplyCityPlanSalesmanDownLineUsersApi = (userId, params) => {
    return axios.get(`${apiConfig}/backyard/promoters/${userId}/users`, {
        params,
    });
};

/**
 * 城市推廣金紀錄
 * @param { type String or Number(字串或物件) } userId
 * @param { type Object(物件) } params
 * @example {
 *  @key userId: 1
 *  @key params: {
 *    @key page: 2,
 *    @key limit: 30
 *  }
 * }
 */
export const GetSalesmanIncomeRecordListApi = async (userId, params) => {
    return axios.get(`${apiConfig}/backyard/promoters/${userId}/datings`, {
        params,
    });
};

/**
 * 當月城市推廣員統計資料
 * @param { type String or Number(字串或數字) } userId
 */
export const GetCityPlanCurrentMonthReportApi = async (userId) => {
    return axios.get(
        `${apiConfig}/backyard/promoters/${userId}/statements/this-month`
    );
};

/**
 * 取得城市推廣人撥款記錄列表
 * @param {*} params
 * @example {
 *  @key statuses: 1 { type String(字串) } 申請狀態
 *  @key phone: 886955831 { type String(字串) } 電話
 *  @key sort_by: name { type String(字串) } 排序欄位名稱
 *  @key sort_type: desc or asc { type String(字串) } 排序方式（倒序或者正序）
 *  @key created_at_start: 2022-12-22 { type DateString(日期字串) } 開始時間
 *  @key created_at_end: 2022-12-26 { type DateString(日期字串) } 結束時間
 * }
 */
export const GetSalesmanSalaryWithdrawRecordListApi = (params) => {
    return axios.get(`${apiConfig}/backyard/search-promoter-statements`, {
        params,
    });
};

/**
 * 取得單一城市推廣人撥款訂單記錄
 * @param { type String or Number(字串或數字) } id 撥款紀錄 id
 */
export const GetSalemanSalaryWithdrawRecordApi = (id) => {
    return axios.get(`${apiConfig}/backyard/promoter-statements/${id}`);
};
/**
 * 更新單一城市推廣人撥款訂單記錄
 * @param { type String or Number(字串或數字) } id 撥款紀錄 id
 * @param { type Object(物件) } form
 * @example {
 *  @key status: -1 { type Number(數字) } 狀態
 *  @key assignee: 1 { type Number(數字) }
 *  @key description: 撥款數字有問題 { type String(字串) } 描述
 * }
 */
export const UpdateSalemanSalaryWithdrawRecordApi = (id, form) => {
    return axios.patch(`${apiConfig}/backyard/promoter-statements/${id}`, form);
};

/**
 * 更新多筆城市推廣人撥款訂單記錄
 * @param { type String or Number(字串或數字) } id 撥款紀錄 id
 * @param { type Array(陣列) } ids 撥款紀錄 id
 * @param { type Number(數字) } status 狀態
 * @example {
 *  @key status: 1 { type Number(數字) } 狀態
 *  @key ids: 1 { type Number(數字) }
 *  @key description: 撥款數字有問題 { type String(字串) } 描述
 * }
 */
export const BulkUpdateSalemanSalaryWithdrawRecordApi = ({ ids, status }) => {
    return axios.patch(`${apiConfig}/backyard/promoter-statements/`, {
        ids,
        status,
    });
};

/**
 * 檢查城市推廣人撥款紀錄狀態
 * @param { type Array(陣列) } ids
 * @example [1,2,3,11]
 * @return 
 * {
    "passed": [
        1,
        11,
        12
    ],
    "failed": []
    }
 */
export const CheckSalesmanWithdrawStatusApi = (ids) => {
    return axios.post(`${apiConfig}/backyard/check-promoter-statements`, ids);
};

/**
 * 匯出城市推廣人撥款帳戶資料
 * @param { type Object(物件) } form
 * @example {
 *  @key ids: [1.2] { type Array(陣列) } 導出 id
 *  @key date: "20230108" { type String(字串) } 導出日期
 * }
 */
export const ExportSalesmanWithdrawDatasApi = (form) => {
    return axios.post(`${apiConfig}/backyard/export-promoter-statements`, form);
};

export default {
    GetApplyCityPlanSalesmanListApi,
    GetApplyCityPlanSalesmanApi,
    VerifyCityPlanSalesmanApi,
    UpdateCityPlanSalesmanStatusApi,
    UpdateCityPlanSalesmanBankAccountApi,
    GetApplyCityPlanSalesmanDownLineUsersApi,
    GetSalesmanIncomeRecordListApi,
    GetCityPlanCurrentMonthReportApi,
    GetSalesmanSalaryWithdrawRecordListApi,
    UpdateSalemanSalaryWithdrawRecordApi,
    BulkUpdateSalemanSalaryWithdrawRecordApi,
    CheckSalesmanWithdrawStatusApi,
    ExportSalesmanWithdrawDatasApi,
    GetSalemanSalaryWithdrawRecordApi,
};
