import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;

/**
 * 取得經紀人列表
 * @param { type Object(物件) } params 過濾參數
 * @example {
 * page: (type Number(數字)) 分頁頁碼
 * name: 暱稱 type String(字串)
 * phone: 手機號碼 type String(字串) ex:886912321321
 * status: 啟用狀態 type String or Number(字串或數字) 0: 使用中 1 停權 2 永久停權
 * government_id: 身份證字號 type String(字串)
 * limie: 20 (type Number) 單頁呈現幾筆資料,
 * sort_by: (type String) 排序依據key,
 * sort_type: (type String) desc or asc 倒序或正序 (desc 倒序 asc 正序)
 * }
 * @returns
 */
export const GetBrokersListApi = (params) => {
    return axios.get(`${apiConfig}/backyard/brokers`, { params });
};

/**
 * 新增經紀人
 * @param { type Object(物件) } params 過濾參數
 * @example {
        "login": "[required | string | between:1,32]",
        "name": "[required | string]",
        "representative": "[required | string]",
        "phone": "[required | digits_between:10,20]",
        "email": "[required | email:rfc,dns,spoof,filter]",
        "password": "[string | min:6]",
        "role": "[integer]",
        "government_cert": "[nullable | integer]",
        "government_id": "[nullable | string]",
        "setting": "[array]",
        "banking": "[array]",
        "plan": "[integer]",
        "status": "[integer]"
 * }
 * @returns
 */
export const CreateBrokerApi = (data) => {
    return axios.post(`${apiConfig}/backyard/brokers`, data);
};

/**
 * 取得單一經紀人資料
 * @param { type number } id 經紀人id
 */
export const GetSingleBrokerApi = (id) => {
    return axios.get(`${apiConfig}/backyard/brokers/${id}`);
};

/**
 * 更新單一經紀人資料
 * @param { type number } id 經紀人id
 * @example {
 *  "login": "[required | string | between:1,32]",
    "name": "[required | string]",
    "representative": "[required | string]",
    "phone": "[required | digits_between:10,20]",
    "email": "[required | email:rfc,dns,spoof,filter]",
    "password": "[string | min:6]",
    "role": "[integer]",
    "government_cert": "[nullable | integer]",
    "government_id": "[nullable | string]",
    "setting": "[array]",
    "banking": "[array]",
    "plan": "[integer]",
    "status": "[integer]"
 * 
 * }
 */
export const UpdateSingleBrokerApi = (id, data) => {
    return axios.put(`${apiConfig}/backyard/brokers/${id}`, data);
};

/**
 * 取得單一經紀人帳戶
 * @param { type number } id 經紀人id
 */
export const GetBrokerPassbookApi = (id) => {
    return axios.get(`${apiConfig}/backyard/brokers/${id}/passbook`, {
        responseType: "blob",
    });
};

/**
 * 更新單一經紀人資料
 * @param { type number } id 經紀人id
 * @example {
 *  "swift": "[string]",
    "bankCode": "[integer]",
    "accountId": "[string]",
    "accountName": "[string]",
    "image": "[required | image:jpeg,png,jpg,gif,svg | max:5120]"
 * 
 * }
 */
export const UpdateBrokerPassbookApi = (id, data) => {
    return axios.post(`${apiConfig}/backyard/brokers/${id}/passbook`, data);
};

/**
 * 取得經紀人服務商
 * @param { type number } id 經紀人id
 * @param { type string } phone filter by phone number
 * @param { type number } role filter by role
 * @param { type number } limit items per page, default is 30
 * @param { type string } sort_by column for sort
 * @param { type string } sort_type asc or desc
 */
export const GetBrokerProviderApi = (id, params) => {
    return axios.get(`${apiConfig}/backyard/brokers/${id}/providers`, { params });
};

export const CheckLoginExistApi = (login) => {
    return axios.get(`${apiConfig}/backyard/brokers/${login}/exists`);
};

/**
 * 款項資訊
 * @param { type Object(物件) } params 搜尋條件
 * @example {
 *  limit: items per page, default is 30
 *  year: filter by year
 *  month: filter by month
 * }
 * @returns
 */

const SearchTransactionsByBrokers = (id, params) => {
    return axios.get(`${apiConfig}/backyard/brokers/${id}/transactions`, { params });
};

/**
 * 月份款項人員資訊
 * @param { type Object(物件) } params 搜尋條件
 * @example {
 *  limit: items per page, default is 30
 *  year: filter by year
 *  month: filter by month
 * }
 * @returns
 */

const SearchTransactionsByMonth = (id, params) => {
    return axios.get(`${apiConfig}/backyard/brokers/${id}/transactions/${params.year}/${params.month}`, { params });
};

/**
 * 標註功能
 * @example {
 *  note: 內容
 *  significant: 重點註記
 * }
 * @returns
 */

const CreateBrokerSupportLog = (id, data) => {
    return axios.post(`${apiConfig}/backyard/brokers/${id}/broker-support-logs`, data);
};

/**
 * 取得經紀人標註
 * @example {
 *  limit: 限制
 * }
 * @returns
 */

const GetBrokerSupportLog = (id, params) => {
    return axios.get(`${apiConfig}/backyard/brokers/${id}/broker-support-logs`, { params });
};

const GetBrokerSubmitApplicationApi = (params) => {
    return axios.get(`${apiConfig}/backyard/search-broker-remittances`, { params });
};

export default {
    GetBrokersListApi,
    CreateBrokerApi,
    GetSingleBrokerApi,
    UpdateSingleBrokerApi,
    GetBrokerPassbookApi,
    UpdateBrokerPassbookApi,
    GetBrokerProviderApi,
    CheckLoginExistApi,
    SearchTransactionsByBrokers,
    SearchTransactionsByMonth,
    CreateBrokerSupportLog,
    GetBrokerSupportLog,
    GetBrokerSubmitApplicationApi,
};
