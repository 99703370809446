export default {
    namespaced: true,
    state: {
        // city ai 選中的服務商 id
        currentCityAiProviderId: null,
        // city ai 選中的服務商 id
        currentCityAiReceiverId: null,
        // 服務商的聊天對象資料
        currentCityAiReceiverUserInfo: {
            userData: {},
        },
        // 當前選擇服務商聊天列表麵包屑
        currentBreadcrumb: {
            provider: null,
            providerReceiver: null,
        },
    },
    getters: {},
    mutations: {
        // 設定city ai 選擇服務商 id
        setCurrentCityAiProviderId(state, val) {
            state.currentCityAiProviderId = val;
        },
        // 設定city ai 選擇服務商聊天對象 id
        setCurrentCityAiReceiverId(state, val) {
            state.currentCityAiReceiverId = val;
        },
        // 設定city ai 選擇服務商聊天對像資料
        setCurrentCityAiReceiverUserInfo(state, val) {
            state.currentCityAiReceiverUserInfo = val;
        },
        // 設定選中服務商麵包屑
        setCurrentBreadcrumbByProvider(state, val) {
            state.currentBreadcrumb["provider"] = val;
        },
        // 設定選中服務商聊天對象麵包屑
        setCurrentBreadcrumbByReceiver(state, val) {
            state.currentBreadcrumb["providerReceiver"] = val;
        },
    },
};
