import Vue from "vue";
import Router from "vue-router";
import { getStorage } from "@/service/localStorage";
import store from "@/store";
import { Loading } from "element-ui";
/**
 * checkVersion: 指定系統 取得對應版本號判斷是否重新整理頁面
 */
import { checkVersion } from "@/service/clientVersionRefreshPageService";

Vue.use(Router);

let router = null;

let loading;

function startLoading() {
    loading = Loading.service({
        lock: true,
        text: "Loding Now...",
        background: "rgba(0,0,0,.7)",
    });
}

function endLoading() {
    loading.close();
}

// 初始路由
var routerOption = {
    // mode: "history",
    // base: "/",
    routes: [
        {
            path: "/login",
            name: "login",
            component: () => import("@/views/login/Login.vue"),
        },
    ],
};

export default function setCMSRouter() {
    router = new Router(routerOption);
    router.beforeEach(async (to, from, next) => {
        startLoading();
        if (to.meta.text) {
            document.title = `管理後台 - ` + to.meta.text;
        }
        function auth(auth) {
            return to.matched.some((record) => {
                return record.meta[auth];
            });
        }
        // 獲取登入token從 localStorage
        const isLogin = getStorage("token") !== null ? true : false;
        // 將crud 存入store
        store.commit("permissionStore/setCurrentPageCRUD", to.meta.params);
        // 判斷是否為登入頁 如果是的話繼續
        if (!isLogin) {
            // 判斷是否登入 如果沒有登入，倒回登入頁，如果有登入則判斷是否有匹配且不等於requiresAuth
            if (to.matched.length > 0 && !auth("requiresAuth")) {
                next();
            } else {
                next({ name: "login" });
            }
        } else {
            // 取的個人資料
            if (Object.keys(store.state.userStore.user).length <= 0) {
                // console.log(Object.keys(store.state.userStore.user).length);
                await store.dispatch("userStore/getLoginUserInfo");
            } else {
                await checkVersion("cmsVersion", "cms_version");
            }
            // 判斷沒有錯誤語系檔時要去抓取
            if (Object.keys(store.state.i18nStore.errorMessages).length <= 0) {
                await store.dispatch("i18nStore/getErrorMessagesList");
            }
            // // 取得後台語系檔
            // if (store.state.i18nStore.langData === null) {
            //     async function getLang() {
            //         await store.dispatch("i18nStore/getDefalutLangData", getStorage("lang"));
            //     }
            //     getLang();
            // }
            // 判斷permissionList為空時執行獲取權限路由api
            if (store.state.permissionStore.permissionList === null) {
                await store.dispatch("permissionStore/getPermissionList");
                next({
                    path: to.path,
                });
            } else {
                if (to.name !== "login") {
                    next();
                } else {
                    next(from.fullPath);
                }
            }
        }
    });
    router.afterEach((to) => {
        endLoading();
        const routerList = [...to.matched];
        // 取得麵包屑路由
        store.commit("breadcrumbStore/setCrumbList", routerList);
        // 取得當前路由
        // store.commit("permissionStore/setCurrentMenu", to.name);
    });

    return router;
}

export const defaultRouter = [
    // cms路由設定
    {
        path: "",
        name: "layout",
        redirect: "dashboard",
        component: () => import("@/layout/cms/Content.vue"),
        meta: {
            requiresAuth: true,
            text: "數據中心",
        },
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                icon: "fas fa-tachometer-alt",
                component: () => import("@/views/dataDashboard/index.vue"),
                meta: {
                    title: "Home Page - Example App",
                    name: "dashboard",
                    text: "數據中心",
                    icon: "fab fa-angellist",
                    custom_route_name: "dashboard",
                },
            },
        ],
    },
    // 找不到頁面路由
    {
        path: "*",
        name: "notfound",
        component: (resolve) => require(["@/views/404.vue"], resolve),
    },
];
