export default {
    namespaced: true,
    state: {
        // 預訂時間
        bookingTime: "",
    },
    getters: {
        newBookingTime: (state) => {
            return state.bookingTime;
        },
    },
    mutations: {
        setBookingTime(state, val) {
            {
                state.bookingTime = val;
            }
        },
    },
};
