import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;
// nestjs api 網址
const apiConfigByNestjs = process.env.VUE_APP_NESTJS_APICONFIG;

/**
 * 訂單紀錄
 * @param { type Number(數字) } id 使用者 id
 * @param { type Object(物件) } params 搜尋條件
 * @example {
 *  limit: items per page, default is 30
 *  sort_by: either created_at or started_at or gross_price
 *  sort_type: asc or desc
 *  role: required, 0 means consumer and 1 means provider
 *  order_id: order identity
 *  started_at_start: the min date of started_at
 *  started_at_end: the max date of started_at
 *  category_id: dating category identity
 *  status: dating status
 *  rated_only: 1 means listing rating score > 0 only
 * }
 * @returns
 */

const SearchDatings = (id, params) => {
    return axios.get(`${apiConfig}/backyard/users/${id}/datings`, { params });
};

const GetDating = (id) => {
    return axios.get(`${apiConfig}/backyard/datings/${id}`);
};

const SearchAllDatings = (params) => {
    return axios.get(`${apiConfig}/backyard/datings`, { params });
};

/**
 * 更新約會紀錄
 * @example {
 *  status: [required | integer | in:-1, -3, -4, 6]
 *  feedback_id:  客訴單編號
 *  note: 變更原因
 * }
 * @returns
 */

const UpdateDating = (id, params) => {
    return axios.patch(`${apiConfig}/backyard/datings/${id}`, params);
};

/**
 * 金流紀錄
 * @param { type Object(物件) } params 搜尋條件
 * @example {
 *  limit: items per page, default is 30
 *  transaction_id: transaction identity
 *  created_at_start:  the min date of created_at
 *  created_at_end: the max date of created_at
 *  type: transaction type
 * }
 * @returns
 */

const SearchTransactions = (id, params) => {
    return axios.get(`${apiConfig}/backyard/users/${id}/transactions`, {
        params,
    });
};

/**
 * 評價紀錄
 * @param { type Object(物件) } params 搜尋條件
 * @example {
 *  limit: items per page, default is 30
 *  sort_by: either created_at or started_at or gross_price
 *  sort_type: asc or desc
 *  role: required, 0 means consumer and 1 means provider
 *  order_id: order identity
 *  started_at_start: the min date of started_at
 *  started_at_end: the max date of started_at
 *  category_id: dating category identity
 *  status: dating status
 *  rated_only: 1 means listing rating score > 0 only
 * }
 * @returns
 */

const SearchDatingByUser = (id, params) => {
    return axios.get(`${apiConfig}/backyard/users/${id}/datings`, { params });
};

/**
 * 評價分數統計
 * @param { type String } id 搜尋條件
 * @returns
 */

const GetSummaryRatting = (id, params) => {
    return axios.get(`${apiConfig}/backyard/users/${id}/rating-summary`, {
        params,
    });
};

/**
 * 新增扣除餘額
 * @param { type id } id userID
 * @param { type String } feedback_id 客訴單號
 * @param { type Number } amount 金額
 * @returns
 */

const UpdateUserBalance = (id, params) => {
    return axios.post(`${apiConfig}/backyard/users/${id}/balance`, params);
};

/**
 * 取得訂單狀態更新LOGS
 * @param { type id } id 訂單編號
 */

const GetOrderLogs = (id) => {
    return axios.get(`${apiConfig}/backyard/datings/${id}/status-logs`);
};

/**
 * 取得使用者快閃折抵金紀錄
 * @param { type number } limit items per page
 * @param { type string } sort_by orting by which column
 * @param { type string } sort_type either asc or desc
 */

const GetUserVoucherList = (id, params) => {
    return axios.get(`${apiConfig}/backyard/users/${id}/voucher-logs`, {
        params,
    });
};

/**
 * 取得使用者快閃折抵金紀錄
 * @param { type number } limit items per page
 * @param { type string } sort_by orting by which column
 * @param { type string } sort_type either asc or desc
 */

const CreateUserVoucher = (id, data) => {
    return axios.post(`${apiConfig}/backyard/users/${id}/vouchers`, data);
};

/**
 * 取得使用者快閃折抵金紀錄
 * @param { type number } limit items per page
 * @param { type string } sort_by orting by which column
 * @param { type string } sort_type either asc or desc
 */

const GetUserVoucherTickets = (id, params) => {
    return axios.get(`${apiConfig}/backyard/users/${id}/vouchers`, { params });
};

/**
 * 更新折抵金狀態
 * @param { type number } feedback_id feedback_id
 * @param { type number } status  in:0 (正常),-1 (過期), -2 (收回)]
 * @param { type string } code 折抵金編號
 */

const UpdateSingleVoucher = (code, data) => {
    return axios.patch(`${apiConfig}/backyard/vouchers/${code}`, data);
};

/**
 * 更改約會狀態前先確認輸入後已付儲值金額
 * @param { type id } id dating_id
 * @param { type number } price feedback_id
 * @param { type number } fee  平台服務費
 * @param { type number } voucher 優惠券
 */

const CheckDatingDryrun = (id, data) => {
    return axios.patch(`${apiConfig}/backyard/datings/${id}/dryrun`, data);
};

/**
 * 更新動態即刻快閃單 status 3 狀態改為2
 * 並且將 started_at 改為 null
 * @param { type String or Number(字串或數字) } id  更改訂單id
 * @param { type Object(物件) } data 更改資料
 * @example  data {
 *   @param { type String(字串) } note 變更原因
 *   @param { type Number(數字) } feedback_id 客訴單 id
 * }
 */
const ChangeNowActivityOrderStatus3ToStatus2Api = (id, data) => {
    return axios.patch(`${apiConfig}/backyard/dating-at-any-time/${id}`, data);
};

/**
 * 新增即刻快閃活動 api
 * @param { type Objectl(物件) } params 搜尋條件
 * @example {
 * user_id: 開立即刻快閃活動的使用者 id (type: Number(數字))
 * name: 活動名稱 (type: String(字串))
 * provider_required: 需求人數 (type: Number(數字))
 * hourly_pay: 每小時出席費 (type: Number(數字))
 * district: 活動地區 (type: String(字串))
 * location: 活動詳細地點 (type: String(字串))
 * due_at: 招募截止時間 (type: Date(YYYY-MM-DD hh:mm 時間格式))
 * started_at: 活動開始時間 (type: Date(YYYY-MM-DD hh:mm 時間格式))
 * duration: 活動時數 (type: Number(數字))
 * description: 活動內容 (type: String(字串))
 * pay_voucher: 是否使用折抵金 (type: Number(數字)) 傳送值 1 代表使用哲金 0 代表不使用
 * }
 * @returns
 */
const CreateRightNowActivityApi = (form) => {
    return axios.post(`${apiConfig}/backyard/demands/datings`, form);
};

/**
 * 新增現金即刻快閃活動 api
 * @param { type Objectl(物件) } params 搜尋條件
 * @example {
 * user_id: 開立即刻快閃活動的使用者 id (type: Number(數字))
 * name: 活動名稱 (type: String(字串))
 * provider_required: 需求人數 (type: Number(數字))
 * hourly_pay: 每小時出席費 (type: Number(數字))
 * district: 活動地區 (type: String(字串))
 * location: 活動詳細地點 (type: String(字串))
 * due_at: 招募截止時間 (type: Date(YYYY-MM-DD hh:mm 時間格式))
 * started_at: 活動開始時間 (type: Date(YYYY-MM-DD hh:mm 時間格式))
 * duration: 活動時數 (type: Number(數字))
 * description: 活動內容 (type: String(字串))
 * pay_voucher: 是否使用折抵金 (type: Number(數字)) 傳送值 1 代表使用哲金 0 代表不使用
 * }
 * @returns
 */
const CreateRightNowActivityByCashApi = (form) => {
    return axios.post(`${apiConfig}/backyard/cash/demands/datings`, form);
};

/**
 * 取得可約會日期與時間
 * @param { type String(字串) } providerId 供給者 id
 * @param { type String(字串) } categoryId 分類 id
 */
const GetCategoryDatingAvailableTimesApi = (providerId, categoryId) => {
    return axios.get(
        `${apiConfig}/providers/${providerId}/categories/${categoryId}/available-times`
    );
};

/**
 * 新增預訂單
 * @param { type Object(物件) } form 表單資料
 * @example {
 * provider_id: 服務商id = banana_id (type String(字串))
 * category_id: 分類id(type String or Number(字串或數字)
 * date: 約會日期 (type String(字串))
 * time: 約會時間 (type String(字串))
 * duration: 約會時常 (type Number(數字))
 * district: 地區 (type String(字串))
 * location: 地點 (type String(字串))
 * tip: 小費 (type Number(數字))
 * description: 約會備註 (type String(字串)) }
 */
export const CreateDateOrderApi = (form) => {
    return axios.post(`${apiConfig}/backyard/datings`, form);
};

/**
 * 新增現金預訂單
 * @param { type Object(物件) } form 表單資料
 * @example {
 * provider_id: 服務商id = banana_id (type String(字串))
 * category_id: 分類id(type String or Number(字串或數字)
 * date: 約會日期 (type String(字串))
 * time: 約會時間 (type String(字串))
 * duration: 約會時常 (type Number(數字))
 * district: 地區 (type String(字串))
 * location: 地點 (type String(字串))
 * tip: 小費 (type Number(數字))
 * description: 約會備註 (type String(字串)) }
 */
export const CreateDateByCashOrderApi = (form) => {
    return axios.post(`${apiConfig}/backyard/cash/datings`, form);
};

export default {
    SearchAllDatings,
    SearchDatings,
    GetDating,
    UpdateDating,
    SearchTransactions,
    SearchDatingByUser,
    GetSummaryRatting,
    UpdateUserBalance,
    GetOrderLogs,
    GetUserVoucherList,
    CreateUserVoucher,
    GetUserVoucherTickets,
    UpdateSingleVoucher,
    CheckDatingDryrun,
    GetCategoryDatingAvailableTimesApi,
    CreateDateOrderApi,
    CreateDateByCashOrderApi,
    CreateRightNowActivityApi,
    CreateRightNowActivityByCashApi,
    ChangeNowActivityOrderStatus3ToStatus2Api,
};
