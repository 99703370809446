import axios from "@/config/httpConfig";
// nestjs api 網址
const apiConfigByNestjs = process.env.VUE_APP_NESTJS_APICONFIG;
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;

/**
 * 行銷圖片上傳 api
 * https://github.com/citybanana/citybanana-document/blob/main/doc/specifications/cms/upload-expo-modal-image.md
 * @example {
 *  @param { type File(檔案) } imgage 檔案格式 (jpg,jpeg,png,gif,svg) 最大限制 10mb
 * }
 */
export const MarketUploadImgApi = (image) => {
    return axios.post(`${apiConfig}/backyard/expo/modals/images`, image);
};

/**
 * 行銷圖片列表
 * https://github.com/citybanana/citybanana-document/blob/main/doc/specifications/cms/modals.md
 */
export const MarketListApi = () => {
    return axios.get(`${apiConfig}/backyard/expo/modals`);
};

/**
 * 行銷圖片更新排序與網址
 * https://github.com/citybanana/citybanana-document/blob/main/doc/specifications/cms/update-modal.md
 * @param { type Array(陣列) } datas 行銷資訊
 * @example {
 *   @param { type String(字串) } id 行銷資訊 id
 *   @param { type String(字串) } url 行銷資訊網址
 * }
 */
export const MarketUpdateApi = (datas) => {
    return axios.put(`${apiConfig}/backyard/expo/modals`, { modals: datas });
};

/**
 * 刪除行銷圖片
 * https://github.com/citybanana/citybanana-document/blob/main/doc/specifications/cms/destroy-modal.md
 * @param { type String(字串) } id 行銷資訊 id
 */
export const MarketDeleteApi = (id) => {
    return axios.delete(`${apiConfig}/backyard/expo/modals/${id}`);
};

export default {
    MarketUploadImgApi,
    MarketListApi,
    MarketUpdateApi,
    MarketDeleteApi,
};
