const templateSettingRouter = {
    path: "/template_setting",
    name: "template_setting",
    icon: "fas fa-home",
    component: () =>
        import("@/views/layout/childLayout/ChildLayoutSetting.vue"),
    meta: {
        name: "template_setting",
        text: "樣板設定",
        custom_route_name: "index_top_carousel",
    },
    children: [
        {
            path: "index_top_carousel",
            name: "index_top_carousel",
            component: () =>
                import("@/views/templateSetting/indexTopCarousel/index.jsx"),
            meta: {
                name: "index_top_carousel",
                text: "首頁上方區塊",
                parent: "template_setting",
                isMenu: false,
            },
        },
        {
            path: "index_top_create_order",
            name: "index_top_create_order",
            component: () =>
                import("@/views/templateSetting/indexCreateOrder/index.jsx"),
            meta: {
                name: "index_top_create_order",
                text: "首頁上方開單區塊",
                parent: "template_setting",
                isMenu: false,
            },
        },
        {
            path: "index_section1",
            name: "index_section1",
            component: () =>
                import("@/views/templateSetting/indexSection1/index.jsx"),
            meta: {
                name: "index_section1",
                text: "首頁介紹區塊",
                parent: "template_setting",
                isMenu: false,
            },
        },
        {
            path: "index_app_download",
            name: "index_app_download",
            component: () =>
                import("@/views/templateSetting/indexAppDownload/index.jsx"),
            meta: {
                name: "index_app_download",
                text: "首頁app下載區塊",
                parent: "template_setting",
                isMenu: false,
            },
        },
        {
            path: "index_activities",
            name: "index_activities",
            component: () =>
                import("@/views/templateSetting/indexActivities/index.jsx"),
            meta: {
                name: "index_activities",
                text: "首頁可參與活動區塊",
                parent: "template_setting",
                isMenu: false,
            },
        },
        {
            path: "index_news",
            name: "index_news",
            component: () =>
                import("@/views/templateSetting/indexNews/index.jsx"),
            meta: {
                name: "index_news",
                text: "首頁最新消息區塊",
                parent: "template_setting",
                isMenu: false,
            },
        },
        {
            path: "index_join_provider",
            name: "index_join_provider",
            component: () =>
                import("@/views/templateSetting/indexJoinProvider/index.jsx"),
            meta: {
                name: "index_join_provider",
                text: "首頁加入我們區塊",
                parent: "template_setting",
                isMenu: false,
            },
        },
        {
            path: "index_medias",
            name: "index_medias",
            component: () =>
                import("@/views/templateSetting/indexMedias/index.jsx"),
            meta: {
                name: "index_medias",
                text: "首頁媒體區塊",
                parent: "template_setting",
                isMenu: false,
            },
        },
        {
            path: "index_what_we",
            name: "index_what_we",
            component: () =>
                import("@/views/templateSetting/indexWhatWe/index.jsx"),
            meta: {
                name: "index_what_we",
                text: "首頁關於我們區塊",
                parent: "template_setting",
                isMenu: false,
            },
        },
        {
            path: "index_choose_we",
            name: "index_choose_we",
            component: () =>
                import("@/views/templateSetting/indexChooseWe/index.jsx"),
            meta: {
                name: "index_choose_we",
                text: "首頁選擇我們區塊",
                parent: "template_setting",
                isMenu: false,
            },
        },
    ],
};

export { templateSettingRouter };
