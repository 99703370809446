import Vue from "vue";
import PopupConfirm from "./PopupConfirm.vue";
import PopupTypeConfirm from "./PopupTypeConfirm.vue";

const PopConfirmConstructor = Vue.extend(PopupConfirm);
const PopTypeConfirmConstructor = Vue.extend(PopupTypeConfirm);

const renderPopConfirm = (opts) => {
    const instance = new PopConfirmConstructor({
        el: document.createElement("div"),
    });
    Object.assign(instance.param, {
        message: opts.message,
        title: opts.title,
        disabled: opts.disabled ? true : false, //確認按鈕是否 disabled
    });
    document.body.appendChild(instance.$el);
    Vue.nextTick(() => {
        instance.param.show = true;
    });
    return instance;
};
const popConfirm = function (opts) {
    const instance = renderPopConfirm(opts);
    const p = new Promise((resolve, reject) => {
        instance.param.cancel = () => {
            reject();
        };
        instance.param.confirm = () => {
            resolve();
        };
    });
    return p;
};

const renderPopConfirmType = (opts) => {
    const instance = new PopTypeConfirmConstructor({
        el: document.createElement("div"),
    });
    Object.assign(instance.param, {
        message: opts.message,
        title: opts.title,
    });
    document.body.appendChild(instance.$el);
    Vue.nextTick(() => {
        instance.param.show = true;
    });
    return instance;
};
const popConfirmType = function (opts) {
    const instance = renderPopConfirmType(opts);
    const p = new Promise((resolve, reject) => {
        instance.param.cancel = () => {
            reject();
        };
        instance.param.confirm = () => {
            resolve();
        };
    });
    return p;
};

export { popConfirm, popConfirmType };
