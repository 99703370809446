import axios from "@/config/httpConfig";

// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;

/**
 * 取得 Tag 列表
 * @param { type Object(物件) } params 過濾參數
 * @example {
 * sort_by: 排序key
 * sort_type: DESC or ASC 倒序或正序
 * name: 標籤名稱
 * }
 * @returns
 */
export const GetTagListApi = (params) => {
    return axios.get(`${apiConfig}/backyard/tags`, { params });
};

/**
 * 新增標籤
 * @param { type String(字串) } name 名稱
 * @returns
 */
export const AddTagApi = (name) => {
    return axios.post(`${apiConfig}/backyard/tags`, { name });
};

/**
 * 更新標籤
 * @param { type Number or String(數字或字串) } id tag id
 * @param { type String(字串) } name 名稱
 * @returns
 */
export const UpdateTagApi = (id, name) => {
    return axios.put(`${apiConfig}/backyard/tags/${id}`, { name });
};

/**
 * 取得指定對象設定的標籤
 * @param { type String(字串) } userId
 */
export const GetUserTagsApi = (userId) => {
    return axios.get(`${apiConfig}/users/${userId}`);
};
/**
 * 更新指定對象設定的標籤
 * @param { type String(字串) } userId
 * @param { type Array(陣列) } tagIds
 */
export const UpdateUserTagsApi = (userId, tagIds) => {
    return axios.post(`${apiConfig}/backyard/users/${userId}/tags`, { tags: tagIds });
};

export default {
    GetTagListApi,
    AddTagApi,
    UpdateTagApi,
    GetUserTagsApi,
    UpdateUserTagsApi,
};
