// 訂單狀態
const orderStatus = {
    1: "等待確認",
    2: "已確認",
    3: "進行中",
    4: "已完成",
    6: "已完成",
    "-1": "服務商取消",
    "-2": "會員取消",
    "-3": "會員臨時取消",
    "-4": "爭議處理中",
};

// 快閃訂單狀態
const flashOrderStatus = {
    0: " 開放報名中",
    1: "報名額滿",
    2: "報名截止",
    "-1": "活動取消",
    "-2": "活動終止",
};

// 快閃活動服務商報名狀態
const flashProviderSignUpStatus = {
    0: "待確認",
    1: "已接受",
    "-1": "婉拒",
};

// 會員金流紀錄類型
const transactionType = {
    TOP_UP: "儲值",
    CREATE_DATING: "建立訂單",
    UPDATE_DATING: "修改訂單",
    CANCEL_DATING: "取消訂單",
    REJECT_DATING: "臨時取消",
    REFUSE_DATING: "訂單婉拒",
    PREPARE_DATING: "訂單預扣",
    CANCEL_PREPARED_DATING: "預扣返還",
    CREATE_DATING_EXTENSION: "建立續約",
    UPDATE_DATING_EXTENSION: "修改續約",
    CANCEL_DATING_EXTENSION: "取消續約",
    REFUSE_DATING_EXTENSION: "續約婉拒",
    PREPARE_DATING_EXTENSION: "續約預扣",
    CANCEL_PREPARED_DATING_EXTENSION: "預扣返還",
    REFUND_DATING: "退款",
    SETTLE_DATING: "訂單入帳",
    WITHDRAW: "立即提領",
    CHARGE_OFF: "月結款項",
    REFUND: "新增餘額",
    DEDUCTION: "扣除餘額",
    CASH: "扣除餘額",
    TIP_DATING: "小費",
    CREATE_DATING_DEMAND: "建立活動",
    REFUND_DATING_DEMAND: "活動退款",
};

// 會員金流紀錄類型
const transactionUserType = {
    TOP_UP: "儲值",
    CREATE_DATING: "建立訂單",
    UPDATE_DATING: "修改訂單",
    CANCEL_DATING: "取消訂單",
    REJECT_DATING: "臨時取消",
    REFUSE_DATING: "訂單婉拒",
    PREPARE_DATING: "訂單預扣",
    CANCEL_PREPARED_DATING: "預扣返還",
    CREATE_DATING_EXTENSION: "建立續約",
    UPDATE_DATING_EXTENSION: "修改續約",
    CANCEL_DATING_EXTENSION: "取消續約",
    REFUSE_DATING_EXTENSION: "續約婉拒",
    PREPARE_DATING_EXTENSION: "續約預扣",
    REFUND_DATING: "退款",
    REFUND: "新增餘額",
    DEDUCTION: "扣除餘額",
    CREATE_DATING_DEMAND: "建立活動",
    REFUND_DATING_DEMAND: "活動退款",
};

// 服務商金流紀錄類型
const transactionProviderType = {
    REJECT_DATING: "臨時取消",
    SETTLE_DATING: "訂單入帳",
    WITHDRAW: "立即提領",
    CHARGE_OFF: "月結款項",
    REFUND: "新增餘額",
    DEDUCTION: "扣除餘額",
    CREATE_DATING_DEMAND: "建立活動",
    REFUND_DATING_DEMAND: "活動退款",
};

// 續約單狀態
const extensionStatus = {
    1: "等待確認",
    2: "已確認",
    "-1": "服務商取消",
    "-2": "會員取消",
};

// 快閃折抵金狀態
const paymentDiscountEvent = {
    1: "發放快閃折抵金",
    2: "新增快閃折抵金",
    3: "退還快閃折抵金",
    4: "使用快閃折抵金",
    5: "扣除快閃折抵金",
    6: "快閃折抵金過期",
};

// 快閃折抵金狀態
const voucherTypes = {
    EARN: "發放快閃折抵金",
    INCREASE: "新增快閃折抵金",
    REFUND: "退還快閃折抵金",
    BURN: "使用快閃折抵金",
    DEDUCT: "扣除快閃折抵金",
    EXPIRE: "快閃折抵金過期",
};
// 現金單繳回方式
const cashOrderPaymentTypes = {
    VACC: "ATM轉帳",
    BARCODE: "超商條碼",
    TRANSFER: "銀行轉帳",
};

// 現金單繳款狀態
const cashOrderPaymentStatus = {
    0: "尚未繳款",
    1: "等待審核",
    2: "等待繳款",
    3: "等待繳款",
    9: "通過審核",
    "-1": "問題款項",
};
// 會員即刻快閃狀態顯示
const rightNowActivityStatusByMember = {
    0: "開放報名中",
    1: "報名額滿",
    2: "報名截止",
    "-1": "活動取消",
    "-2": "活動終止",
};
// 服務商即刻快閃報名標題顯示
const rightNowActivityStatusByProviderTitle = {
    null: "未報名",
    0: "已報名此活動",
    1: "會員已接受您的報名",
    "-1": "已婉拒",
};

// 現金單服務商繳回紀錄 type
const cashOrderByProviderEscheatLogStatus = {
    paid: "服務商確認收款",
    settled: "服務商繳回",
};
// 現金單服務商收款狀態
const cashOrderByGetCashStatus = {
    0: "尚未繳款",
    1: "已收款",
    2: "已收款",
    3: "已收款",
    9: "確認收款",
    "-1": "問題款項",
};

// 現金單服務商繳回款項狀態
const cashOrderByProviderEscheatStatus = {
    0: "未收款",
    1: "已收款",
    2: "已繳回",
};

export default {
    orderStatus,
    transactionUserType,
    transactionProviderType,
    transactionType,
    extensionStatus,
    paymentDiscountEvent,
    voucherTypes,
    flashOrderStatus,
    flashProviderSignUpStatus,
    cashOrderPaymentTypes,
    cashOrderPaymentStatus,
    rightNowActivityStatusByMember,
    rightNowActivityStatusByProviderTitle,
    cashOrderByProviderEscheatLogStatus,
    cashOrderByGetCashStatus,
    cashOrderByProviderEscheatStatus,
};
