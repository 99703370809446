import Vue from "vue";
// 使用國旗icon
import CountryFlag from "vue-country-flag";
Vue.component("country-flag", CountryFlag);
// 日期選擇套件
import LitepieDatepicker from "vue2-litepie-datepicker";
Vue.use(LitepieDatepicker);
// 日期選擇套件搭配得日期格式套件
import dayjs from "dayjs";
// 日期格式套件語系
import "dayjs/locale/zh-tw";
// 設定語系為繁體中文
dayjs.locale("zh-tw");

// 彈窗組建
import VueSilentbox from "vue-silentbox";
Vue.use(VueSilentbox);

// 確認彈窗
import { popConfirm, popConfirmType } from "../components/popup/pop";
Vue.prototype.$pop = {
    popConfirm,
    popConfirmType,
};
// 複製字串套件
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);
// 導入評分選擇套件
import Raters from "vue-rate-it";
Vue.component("star-rating", Raters.StarRating);
Vue.component("heart-rating", Raters.HeartRating);
Vue.component("fa-rating", Raters.FaRating);
Vue.component("image-rating", Raters.ImageRating);
