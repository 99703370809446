import axios from "@/config/httpConfig";
import { getStorage } from "@/service/localStorage";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;
// nestjs api 網址
const apiConfigByNestjs = process.env.VUE_APP_NESTJS_APICONFIG;

/**
 * 上傳聊天室檔案api
 * @param { type File(檔案格式) } file 圖片或其他附件檔案（暫時只限定傳圖片）
 */
export const ChatUploadAttachmentsApi = (file) => {
    const result = new FormData();
    result.append("file", file.raw);
    return axios.post(`${apiConfig}/backyard/chat/attachments`, result);
};

/**
 * 群發訊息 API
 * @param { type Object(物件) } data
 * @example {
 *   userIds: ["uqvygi5670","uqwofmo478"] { type Array } 要傳送對象 bananaId
 *   message: "重要訊息公告" { type String } 訊息
 * }
 */
export const ChatSendNotifyMessagesApi = (data) => {
    return axios.post(`${apiConfigByNestjs}/chats/send-notify-messages`, { ...data, token: getStorage("token") });
};

/**
 * 客服回應訊息 API
 */
export const ChatSendMessageApi = ({ receiveUserId, message }) => {
    return axios.post(`${apiConfigByNestjs}/chats/send-service-chat-message`, { receiveUserId, message });
};
/**
 * CityAI回應訊息 API
 */
export const ChatSendCityAIMessageApi = ({ loginUserId, receiveUserId, message }) => {
    return axios.post(`${apiConfigByNestjs}/chats/send-cityai-chat-message`, { loginUserId, receiveUserId, message });
};

export default {
    ChatUploadAttachmentsApi,
    ChatSendNotifyMessagesApi,
    ChatSendMessageApi,
    ChatSendCityAIMessageApi,
};
