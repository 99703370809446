/**
 * firebaseGetClientVersion: 取得指定系統 版本號 cms or broker
 */
import { firebaseGetClientVersion } from "@/plugins/firebase";
import { getStorage, setStorage } from "./localStorage";
export const checkVersion = async (storeageVersion, getVersionPath) => {
    // 取得遠端版本號
    const remoteVersion = await firebaseGetClientVersion(getVersionPath).get();
    // 判斷是否有取得遠端版本號 沒取得不往下執行
    if (!remoteVersion.exists()) {
        return;
    }

    // 取得本地版本號
    const localVersion = getStorage(storeageVersion);
    if (localVersion == null) {
        setStorage(storeageVersion, remoteVersion.val());
        // 重整頁面
        location.reload();
        return;
    }
    // 判斷本地與遠端版本號是否相同 不相同則重整頁面 確保程式碼為最新版本
    if (localVersion !== remoteVersion.val()) {
        setStorage(storeageVersion, remoteVersion.val());
        // 重整頁面
        location.reload();
        return;
    }
    return;
};
