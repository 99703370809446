import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;
// nestjs api 網址
const apiConfigByNestjs = process.env.VUE_APP_NESTJS_APICONFIG;

/**
 * 取得即刻快閃單跑馬燈列表
 * @returns
 */
const GetRightNowActivityMarqueeListApi = () => {
    return axios.get(`${apiConfig}/backyard/marquee/demands/datings`);
};

/**
 * 取得即刻快閃單跑馬燈細節
 * @param { type String(字串) } id 訂單id
 * @returns
 */
const GetRightNowActivityMarqueeDetailApi = (id) => {
    return axios.get(`${apiConfig}/backyard/marquee/demands/datings/${id}`);
};

/**
 * 新增或更新即刻快閃單跑馬燈
 * @param { type String(字串) } id 訂單id
 * @param { type Object(物件) } form 表單資料
 * @example {
 * name: 活動名稱 (type: String(字串))
 * provider_required: 需求人數 (type: Number(數字))
 * hourly_pay: 每小時出席費 (type: Number(數字))
 * }
 * @returns
 */
const CreateOrUpdateRightNowActivityMarqueeDetailApi = (id, form) => {
    console.log(form, "api form");
    return axios.post(
        `${apiConfig}/backyard/marquee/demands/datings/${id}`,
        form
    );
};

/**
 * 刪除即刻快閃單跑馬燈
 * @param { type String(字串) } id 訂單id
 * @returns
 */
const DeleteRightNowActivityMarqueeDetailApi = (id) => {
    return axios.delete(`${apiConfig}/backyard/marquee/demands/datings/${id}`);
};

export default {
    GetRightNowActivityMarqueeListApi,
    GetRightNowActivityMarqueeDetailApi,
    CreateOrUpdateRightNowActivityMarqueeDetailApi,
    DeleteRightNowActivityMarqueeDetailApi,
};
