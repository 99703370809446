import Vue from "vue";
import Router from "vue-router";
import { getStorage } from "@/service/localStorage";
import store from "@/store";
import brokerRouter from "@/router/routers/brokerRouter";
/**
 * checkVersion: 指定系統 取得對應版本號判斷是否重新整理頁面
 */
import { checkVersion } from "@/service/clientVersionRefreshPageService";

Vue.use(Router);

let router = null;

export default function setBrokerRouter() {
    // 初始路由
    router = new Router(brokerRouter);
    router.beforeEach(async (to, from, next) => {
        function auth(auth) {
            return to.matched.some((record) => {
                return record.meta[auth];
            });
        }
        // 獲取登入token從 localStorage
        if (to.meta.text) {
            document.title = `經紀人後台`;
        }
        const isLogin = getStorage("token") !== null ? true : false;
        if (!isLogin) {
            // 判斷是否登入 如果沒有登入，倒回登入頁，如果有登入則判斷是否有匹配且不等於requiresAuth
            if (to.matched.length > 0 && !auth("requiresAuth")) {
                next();
                return;
            } else {
                next({ name: "login" });
            }
        } else {
            if (Object.keys(store.state.userStore.user).length <= 0) {
                await store.dispatch("userStore/getLoginBrokerInfo");
            } else {
                setTimeout(async () => {
                    await checkVersion("brokerVersion", "broker_version");
                }, 1500);
            }
            next();
        }
    });
    return router;
}
