import axios from "@/config/httpConfig";
// api 網址
const apiConfig = process.env.VUE_APP_APICONFIG;

/**
 * @param { type Number } limit items per page, default is 30
 * @param { type Number } order_id  order identity
 * @param { type String } phone user's phone number
 * @param { type Number } status payment's status, 0 or 1 or -1 or -2
 * @param { type String } type payment type
 * @param { type String } start_date: payment time after this date
 * @param { type String } end_date: payment time before this date
 * @param { type String } created_at_start: creates time after this date
 * @param { type String } created_at_end: created time before this dat
 * @returns 付款紀錄
 */

export const SearchPaymentsAPI = (params) => {
    return axios.get(`${apiConfig}/backyard/search-payments`, { params });
};

/**
 * @param { type Number } limit items per page, default is 30
 * @param { type Number } status remittances's status, 0(服務商或系統提出提領), 1(會計確認無誤), 2(匯出銀行檔案), 3(完成匯款), -1(發生錯誤)
 * @param { type Number } transaction_log_id  transaction log identity
 * @param { type String } phone user's phone number
 * @param { type String } reason reason of remittances
 * @param { type String } complete_date_start 匯款時間
 * @param { type String } complete_date_end 匯款時間
 * @param { type String } created_date_start 申請時間
 * @param { type String } created_date_end 申請時間
 * @param { type String } sort_by 申請時間 created_at (default), export_time or complete_time
 * @returns 會計列表
 */

export const SearchRemittancesAPI = (params) => {
    return axios.get(`${apiConfig}/backyard/search-remittances`, { params });
};

/**
 * @param { type Number } status 0:SUBMITTED, 1:REVIEWED, 2:EXPORTED, 3:COMPLETED, 4:ERROR
 * @param { type Number } assignee [integer]
 * @param { type String } description description
 * @returns 更新對帳 status
 */

export const UpdateRemittanceStatus = (id, params) => {
    return axios.patch(`${apiConfig}/backyard/remittances/${id}`, params);
};

/**
 * @param { type Number } status 0:SUBMITTED, 1:REVIEWED, 2:EXPORTED, 3:COMPLETED, 4:ERROR
 * @param { type Number } ids [array of remittance identity]
 * @returns 複選更新對帳 status
 */

export const UpdateRemittanceStatusArray = (params) => {
    return axios.patch(`${apiConfig}/backyard/remittances`, params);
};

/**
 * @param { type Number } id remittance's id
 * @returns 取得單筆 remittance 資訊
 */

export const GetRemittanceDetail = (id) => {
    return axios.get(`${apiConfig}/backyard/remittances/${id}`);
};

/**
 * @param { type Array } ids remittance's ids
 * @param { type String } date [required | date:Ymd | after_or_equal:today]
 * @param { type String } comment string
 * @returns 匯出 remittance 單
 */

export const ExportRemittanceText = (params) => {
    return axios.post(`${apiConfig}/backyard/export-remittances`, params, {
        responseType: "arraybuffer",
    });
};

/**
 * 檢查金流匯出資料是否正確
 * @param { type Array } ids remittance's ids
 * @returns { failed , passed }
 */

export const CheckRemittances = (params) => {
    return axios.post(`${apiConfig}/backyard/check-remittances`, params);
};

/**
 * 取得會員錢包餘額
 * limie: 30 (type Number) 單頁呈現幾筆資料,
 * sort_type: (type String) desc or asc 倒序或正序 (desc 倒序 asc 正序)
 */

export const GetMemberWalletsAPI = (params) => {
    return axios.get(`${apiConfig}/backyard/user-wallets`, { params });
};

/**
 * 取得會員錢包餘額
 * limie: 30 (type Number) 單頁呈現幾筆資料,
 * sort_type: (type String) desc or asc 倒序或正序 (desc 倒序 asc 正序)
 */

export const GetMemberWalletSummaryAPI = () => {
    return axios.get(`${apiConfig}/backyard/user-wallet-summary`);
};

/**
 * 介紹人代付款項撥款處理
 * @param { type Number(數字) } limit 每頁呈現幾筆資料 預設 30筆
 * @param { type Number or String(數字或字串) } status 訂單狀態
 * @param { type String(字串) } phone 電話
 * @param { type String(字串) } reason 交易原因
 * @param { type Date(日期) } transaction_date_start 交易開始日期
 * @param { type Date(日期) } transaction_date_end 交易結束日期
 * @param { type Date(日期) } created_date_start 創建日期
 * @param { type Date(日期) } created_date_end 結束日期
 * @param { type String(字串) } sort_by 排序條件
 */
export const GetBrokerWaitRemittancesApi = (params) => {
    return axios.get(`${apiConfig}/backyard/search-broker-remittances`, {
        params,
    });
};
/**
 * 取得介紹人單一代付款項資料
 * @param { type String(字串) } id 訂單編號
 */
export const GetBrokerRemittanceApi = (id) => {
    return axios.get(`${apiConfig}/backyard/broker-remittances/${id}`);
};
/**
 * 更新介紹人單一代付款項資料
 * @param { type String(字串) } id 訂單編號
 */
export const UpdateBrokerRemittanceApi = ({ id, status }) => {
    return axios.patch(`${apiConfig}/backyard/broker-remittances/${id}`, {
        status,
    });
};
/**
 * 更新介紹人多筆代付款項資料
 * @param { type Array(陣列) } ids 多筆訂單編號
 * @param { type Number or String(數字或字串) } status 訂單狀態 (0: 待處理, 1: 已受理, 2: 處理中, 3: 已完成, 4: 失敗)
 */
export const UpdateBrokerMoreRemittancesApi = ({ ids, status }) => {
    return axios.patch(`${apiConfig}/backyard/broker-remittances`, {
        ids,
        status,
    });
};
/**
 * 介紹人匯款資料檢查
 * @param { type Array(陣列) } ids 多筆訂單編號
 */
export const CheckBrokerMoreRemittancesApi = ({ ids }) => {
    return axios.post(`${apiConfig}/backyard/check-broker-remittances`, {
        ids,
    });
};
/**
 * 匯出介紹人匯款資料
 * @param { type Array(陣列) } ids 多筆訂單編號
 * @param { type Date(日期) } date (格式： YYYY-MM-DD) 匯出日期
 */
export const ExportBrokerRemittancesApi = ({ ids, date }) => {
    return axios.post(`${apiConfig}/backyard/export-broker-remittances`, {
        ids,
        date,
    });
};

/**
 * 取得 現金單 服務商未繳回款項訂單列表
 * @param { type Object(物件) } params 過濾參數
 * @example {
 *  limit: { type Number(數字) } 每頁筆數
 *  sort_by: { type String(字串) } 排序指定 key
 *  sort_type: { type String(字串) } 排序規則 (asc | desc)
 *  started_at_start: { type DateString(字串) } 訂單_開始日期 (ex: 2023-03-07)
 *  started_at_end: { type DateString(字串) } 訂單_結束日期 (ex: 2023-03-08)
 *  ended_at_start: { type DateString(字串) } 繳費截止_開始日期 (ex: 2023-03-07)
 *  ended_at_end: { type DateString(字串) } 繳費截止_結束日期 (ex: 2023-03-08)
 * }
 */
export const GetUnpaidCashOrderListApi = (params) => {
    return axios.get(`${apiConfig}/backyard/unpaid-cut-datings`, { params });
};

/**
 * 取得 現金單 (待審核 | 已審核 | 已通過) 款項訂單列表
 * @param { type Object(物件) } params 過濾參數
 * @example {
 *  limit: { type Number(數字) } 每頁筆數
 *  sort_by: { type String(字串) } 排序指定 key
 *  sort_type: { type String(字串) } 排序規則 (asc | desc)
 *  statuses: { type Array(陣列) } 狀態
 *  phone: { type String(字串) } 手機號碼
 *  account: { type String(字串) } 銀行帳號
 *  pay_time_start: { type DateString(字串) } 繳回款項開始時間 (ex: 2023-03-07)
 *  pay_time_end: { type DateString(字串) }  繳回款項結束時間 (ex: 2023-03-08)
 * }
 */
export const GetPaymentBySlipOrderListApi = (params) => {
    return axios.get(`${apiConfig}/backyard/payment-slips`, { params });
};

/**
 * 取得 現金單 繳回款項 訂單細節
 */
export const GetPaymentBySlipOrderDetailApi = (slipId) => {
    return axios.get(`${apiConfig}/backyard/payment-slips/${slipId}`);
};

/**
 * 更新 現金單 繳回款項 狀態
 * @param { type String or Number(字串或數字) } id 訂單編號
 * @param { type Object(物件) } form 表單資料
 * @example {
 *  status: { type Nubmer(數字) } 狀態 (required)
 *  amount: { type Number(數字) } 變更額度 (required)
 *  description { type String(字串) } 描述
 * }
 */
export const UpdatePaymentBySlipOrderStatus = (id, form) => {
    console.log(id, form);
    return axios.patch(`${apiConfig}/backyard/payment-slips/${id}`, form);
};

export default {
    SearchPaymentsAPI,
    SearchRemittancesAPI,
    UpdateRemittanceStatus,
    UpdateRemittanceStatusArray,
    GetRemittanceDetail,
    ExportRemittanceText,
    CheckRemittances,
    GetMemberWalletsAPI,
    GetMemberWalletSummaryAPI,
    GetBrokerWaitRemittancesApi,
    GetBrokerRemittanceApi,
    UpdateBrokerRemittanceApi,
    UpdateBrokerMoreRemittancesApi,
    CheckBrokerMoreRemittancesApi,
    ExportBrokerRemittancesApi,
    GetUnpaidCashOrderListApi,
    GetPaymentBySlipOrderListApi,
    GetPaymentBySlipOrderDetailApi,
    UpdatePaymentBySlipOrderStatus,
};
