import firebase from "firebase/app";
import "firebase/database";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
// import "firebase/messaging";
const config = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
    // apiKey: "AIzaSyD-n8FvjhsZMsTJh84G0a4CUAXvAAomR70",
    // authDomain: "citybanana-cdbe6.firebaseapp.com",
    // projectId: "citybanana-cdbe6",
    // storageBucket: "citybanana-cdbe6.appspot.com",
    // messagingSenderId: "352403513103",
    // appId: "1:352403513103:web:d1a66709762b346ef7a87a",
    // measurementId: "G-H81WSNJ8MW",
    // databaseURL: "https://citybanana-cdbe6-default-rtdb.asia-southeast1.firebasedatabase.app/"
};

let firebaseApp;
// 判斷是否有註冊了 firebase 重複註冊會報錯
if (!firebase.apps.length) {
    // 沒有註冊時執行註冊
    firebaseApp = firebase.initializeApp(config);
} else {
    // 有註冊時則直接呼叫方法
    firebaseApp = firebase.app();
}
export default firebaseApp;

// cloud firestore 連接方式
export const db = () => {
    return firebaseApp.firestore();
};
// 指定連接資料庫
export const chatCollection = () => {
    return firebaseApp.firestore().collection("chats");
};
// firebase google login
export const googleLogin = () => {
    return new firebase.auth.GoogleAuthProvider();
};

// firebase 檔案上傳
export const storage = () => {
    return firebaseApp.storage();
};

// firebase fcm 功能測試
export const firebaseMessaging = () => {
    return firebaseApp.messaging();
};

// firebase realtime database Connect
export const firebaseConnectRef = (ref) => {
    return firebaseApp.database().ref(ref);
};

// firebase auth
export const firebaseAuth = () => {
    return firebaseApp.auth();
};

/**
 * firebase 取得用戶端版本號（用來判斷是否重新整理頁面）
 * @param { type String(字串) } system 判斷是哪個系統的版本號 cms or broker
 * @example {
 * cms_version: cms 版本號
 * broker_version: 經濟後台版本號
 * }
 * @returns
 */
export const firebaseGetClientVersion = (system) => {
    return firebase.database().ref(`product_versions/${system}`);
};
