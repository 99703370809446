const systemControlRouter = {
    path: "/system_control",
    name: "system_control",
    icon: "fas fa-tools",
    component: () => import("@/views/layout/childLayout/ChildLayout.vue"),
    meta: {
        name: "system_control",
        text: "系統",
    },
    children: [
        {
            path: "error_lang",
            name: "error_lang",
            component: () => import("@/views/systemControl/errorLangSet.vue"),
            meta: {
                text: "錯誤訊息設定",
                parent: "system_control",
                isMenu: true,
            },
        },
        {
            path: "register_message_set",
            name: "register_message_set",
            component: () =>
                import("@/views/systemControl/registerMessageSet.vue"),
            meta: {
                text: "註冊發送訊息",
                parent: "system_control",
                isMenu: true,
            },
        },
        {
            path: "provider_default_message",
            name: "provider_default_message",
            component: () =>
                import("@/views/systemControl/providerDefaultMessage.vue"),
            meta: {
                text: "服務商預設訊息樣板",
                parent: "system_control",
                isMenu: true,
            },
        },
        {
            path: "member_template_message",
            name: "member_template_message",
            component: () =>
                import("@/views/systemControl/memberTemplateMessage.jsx"),
            meta: {
                text: "會員罐頭訊息樣板",
                parent: "system_control",
                isMenu: true,
            },
        },
        {
            path: "system_template_message",
            name: "system_template_message",
            component: () =>
                import("@/views/systemControl/systemTemplateMessage.jsx"),
            meta: {
                text: "系統罐頭訊息樣板",
                parent: "system_control",
                isMenu: true,
            },
        },
        {
            path: "product_versions",
            name: "product_versions",
            component: () =>
                import("@/views/systemControl/productVersions.vue"),
            meta: {
                text: "設定產品版本號",
                parent: "system_control",
                isMenu: true,
            },
        },
        {
            path: "export_user_email",
            name: "export_user_email",
            component: () =>
                import("@/views/systemControl/exportUserEmail/index.vue"),
            meta: {
                text: "匯出使用者Email",
                parent: "system_control",
                isMenu: true,
            },
        },
        {
            path: "create_order_step_set",
            name: "create_order_step_set",
            component: () =>
                import("@/views/systemControl/createOrderStepSet.vue"),
            meta: {
                text: "設定開單引導流程",
                parent: "system_control",
                isMenu: true,
            },
        },
        {
            path: "send_notify",
            name: "send_notify",
            component: () =>
                import("@/views/systemControl/sendNotify/index.vue"),
            meta: {
                text: "發送通知訊息",
                parent: "system_control",
                isMenu: true,
            },
        },
        {
            path: "social_media_keywords_replace",
            name: "socialMediaKeywordsReplace",
            component: () =>
                import(
                    "@/views/systemControl/socialMediaKeywordReplace/index.vue"
                ),
            meta: {
                text: "聊天室關鍵字過濾設定",
                parent: "system_control",
                isMenu: true,
            },
        },
        {
            path: "client_ui_setting",
            name: "clientUiSetting",
            component: () =>
                import("@/views/systemControl/clientUiSetting/index.vue"),
            meta: {
                text: "前台頁面顯示設定",
                parent: "system_control",
                isMenu: true,
            },
        },
        {
            path: "client_image_setting",
            name: "clientImageSetting",
            component: () =>
                import("@/views/systemControl/clientImageSetting/index.vue"),
            meta: {
                text: "前台與APP圖片設定",
                parent: "system_control",
                isMenu: true,
            },
        },
        {
            path: "client_booking_setting",
            name: "clientBookingSetting",
            component: () =>
                import("@/views/systemControl/clientBookingSetting/index.jsx"),
            meta: {
                text: "前台預訂基本參數設定",
                parent: "system_control",
                isMenu: true,
            },
        },
        // {
        //     path: "resize_image",
        //     name: "resize_image",
        //     component: () => import("@/views/systemControl/testResizeImage.vue"),
        //     meta: {
        //         text: "重製圖片尺寸",
        //         parent: "system_control",
        //         isMenu: true
        //     }
        // }
    ],
};

export { systemControlRouter };
