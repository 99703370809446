import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 防止vue 在 console視窗多出現 一條已啟動的警告視窗
Vue.config.productionTip = false;
// vue 全域化 mixins
import "./global/mixins";
// vue 全域化 filters
import "./global/filters";
// vue 全域化 prototypes
import "./global/prototypes";
// vue 全域化 組件
import "./global/components";
// 導入所有 api 方法
import "./api/allApi";

// fontawesome icon
import "./plugins/fontawesome";
// 懂入自訂時間格式套件
import "./plugins/moment";
// 圖片裁剪套件
import "./plugins/VueCroppie";
// element ui
import "./plugins/element-ui";

// 自定義scss
import "./scss/main.scss";

// directive 引入
import "@/directive/index.js";

import VueCompositionAPI from "@vue/composition-api";

Vue.use(VueCompositionAPI);


new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
